import React, { useContext, useState, useEffect } from "react";
import theDefaultContext from "../../defaultContext";
import { Backdrop, Button, ButtonBase, Paper } from "@material-ui/core";
import { HorizontalBar, Bar, Radar } from "react-chartjs-2";
//import Axios from "axios";
//import Table from "reactstrap/lib/Table";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
//import LinearProgress from "@material-ui/core/LinearProgress";
//import IconDownloadSymbol from "../../../../../Container/Icons/iconDownloadExcelFile";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
//import { isJSDocTemplateTag } from "typescript";
//import fileDownload from "js-file-download";
import IconStart from "../../../../../Container/Icons/iconStart";
import IconSearch from "../../../../../Container/Icons/iconDatabase";
import Checkbox from '@material-ui/core/Checkbox';

import FormControlLabel from '@material-ui/core/FormControlLabel'; 

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
        },
        color: "#008000",
    },
    root2: {
        justifyContent: "center",
        display: "flex",
        zIndex: 1,
        //position: "absolute", // löst Symbol von restlichen Layern
        flexWrap: "wrap",
        "& > *": {
            width: theme.spacing(60),
            height: theme.spacing("auto"),
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

export default function DataSummaryFunction() {
    //manipulate Material UI CSS -> https://www.youtube.com/watch?v=rEHvPiLWSbQ
    const stylesLoadingChart = {
        rootLadebalken: {
            height: 80,
            backgroundColor: "#ecf01494",
        },

        rootStartButton: {
            //align: "center",
            fontSize: "1.2rem",
            backgroundColor: "#F7F7F7",
            padding: "1rem 1rem 1rem 1rem", // Abstand nach: Höhre BreiteRechts Tiefe BreiteLinks
        },
    };

    const stylesExcel = {
        root: {
            color: "#008000",
        },
    };
    const classes = useStyles();

    let normalise = false;
  //  const useStylesLoadingChart = makeStyles(stylesLoadingChart);
   // const classesLoading = useStylesLoadingChart();

    const useStylesExcel = makeStyles(stylesExcel);
    //const classesExcel = useStylesExcel();

    //Radio-Button Type of Application
    const { finalValueTypeOfApp } = useContext(theDefaultContext);
    finalValueTypeOfApp.toString();
    let typeOfAppLabel = "default";
    if (finalValueTypeOfApp == "SC_Raw_material") {
        typeOfAppLabel = "Raw material grinding";
    } else if (finalValueTypeOfApp == "SC_Crushing") {
        typeOfAppLabel = "Crushing";
    } else if (finalValueTypeOfApp == "SC_Coarse") {
        typeOfAppLabel = "Coarse grinding";
    } else if (finalValueTypeOfApp == "SC_Fine") {
        typeOfAppLabel = "Fine grinding";
    } else if (finalValueTypeOfApp == "SC_Ultrafine") {
        typeOfAppLabel = "Ultra-fine grinding";
    }

    //Slider Alle Custom-Werte aus Scenarios
    const { categoryQualityCustom } = useContext(theDefaultContext);
    const { categoryFeedFlexCustom } = useContext(theDefaultContext);
    const { categoryOperationCustom } = useContext(theDefaultContext);
    const { categoryMaintenanceCustom } = useContext(theDefaultContext);
    const { categoryEfficiencyCustom } = useContext(theDefaultContext);
    const { categoryEnvironmentCustom } = useContext(theDefaultContext);

    //Slider alle PresetKategorien
    const { categoryQuality } = useContext(theDefaultContext);
    const { categoryFeedFlex } = useContext(theDefaultContext);
    const { categoryOperation } = useContext(theDefaultContext);
    const { categoryMaintenance } = useContext(theDefaultContext);
    const { categoryEfficiency } = useContext(theDefaultContext);
    const { categoryEnvironment } = useContext(theDefaultContext);

    //Slider alle Custom-Werte aus Scenario-Details
    const { psd } = useContext(theDefaultContext);
    const { maxFineness } = useContext(theDefaultContext);
    const { dehydration } = useContext(theDefaultContext);
    const { drying } = useContext(theDefaultContext);
    const { maxFeedSize } = useContext(theDefaultContext);
    const { productChangingTime } = useContext(theDefaultContext);
    const { understandingOfProcess } = useContext(theDefaultContext);
    const { sensitivityOfProcess } = useContext(theDefaultContext);
    const { controlabilityPersonnel } = useContext(theDefaultContext);
    const { availableExperience } = useContext(theDefaultContext);
    const { throughput } = useContext(theDefaultContext);
    const { maintenanceTime } = useContext(theDefaultContext);
    const { complexityOfMaintenance } = useContext(theDefaultContext);
    const { wearCosts } = useContext(theDefaultContext);
    const { energyDemand } = useContext(theDefaultContext);
    const { waterDemand } = useContext(theDefaultContext);
    const { dustEmissions } = useContext(theDefaultContext);
    const { noise } = useContext(theDefaultContext);
    const { effectOfVibration } = useContext(theDefaultContext);

    // Mill Selection
    const { millSelectionUserChoice } = useContext(theDefaultContext);
 //   const { millSelectionCountMill } = useContext(theDefaultContext);
 //   const { selectMillReceivedValue } = useContext(theDefaultContext);
    const { allMillsWithoutFilters, setAllMillsWithoutFilters } =
        useContext(theDefaultContext);

    // (SwitchValues) Variablen für Status der aktiven Benutzer-Auswahl für alle Artikel
    const { categoryRadioScenario } = useContext(theDefaultContext);
    const { criteriaRadioScenario } = useContext(theDefaultContext);
    const { limitOfSliderCategorie } = useContext(theDefaultContext);
    const { limitOfSliderCriteria } = useContext(theDefaultContext);
    const { millSelectRadioStatus } = useContext(theDefaultContext);
 
    let FilterStatus = "default";
    if (millSelectRadioStatus == "all") {
        FilterStatus = "active filter";
    } else {
        FilterStatus = "inactive filter";
    }




    // Finale Werte für Axios Formula (Konvertiere String in Float)
    //Categories:
    let finalValueQuality = parseFloat(categoryQuality);
    let finalValueFeedFlex = parseFloat(categoryFeedFlex);
    let finalValueOperation = parseFloat(categoryOperation);
    let finalValueMaintenance = parseFloat(categoryMaintenance);
    let finalValueEfficiency = parseFloat(categoryEfficiency);
    let finalValueEnvironment = parseFloat(categoryEnvironment);

    //Criterias:
    let finalValuePsd = parseFloat(psd);
    let finalValueMaxFineness = parseFloat(maxFineness);
    let finalValueDehydration = parseFloat(dehydration);
    let finalValueDrying = parseFloat(drying);
    let finalValueMaxFeedSize = parseFloat(maxFeedSize);
    let finalValueProductChangingTime = parseFloat(productChangingTime);
    let finalValueUnderstandingOfProcess = parseFloat(understandingOfProcess);
    let finalValueSensitivityOfProcess = parseFloat(sensitivityOfProcess);
    let finalValueControlabilityPersonnel = parseFloat(controlabilityPersonnel);
    let finalValueAvailableExperience = parseFloat(availableExperience);
    let finalValueThroughput = parseFloat(throughput);
    let finalValueMaintenanceTime = parseFloat(maintenanceTime);
    let finalValueComplexityOfMaintenance = parseFloat(complexityOfMaintenance);
    let finalValueWearCosts = parseFloat(wearCosts);
    let finalValueEnergyDemand = parseFloat(energyDemand);
    let finalValueWaterDemand = parseFloat(waterDemand);
    let finalValueDustEmissions = parseFloat(dustEmissions);
    let finalValueNoise = parseFloat(noise);
    let finalValueEffectOfVibration = parseFloat(effectOfVibration);

    // Mapping from Result for BarCharts
    const [finalScore, setFinalScore] = useState([]);
    const [finalList, setFinalList] = useState([]);
    const [options, setOptions] = useState([]);

    ///////////////////////////Variablen für ChartBars
    let finalMillsID = [];
    const [millSelek, setMillSelek] = useState(0);
    const [inspectMill, setInspectMill] = useState("");
    //let finalInspectMill = inspectMill;

    // Avarage for all Category
    const [averageScoreQuality, setAverageScoreQuality] = useState([]);
    const [averageScoreFeed, setAverageScoreFeed] = useState([]);
    const [averageScoreOperation, setAverageScoreOperation] = useState([]);
    const [averageScoreMaintenance, setAverageScoreMaintenance] = useState([]);
    const [averageScoreEfficiency, setAverageScoreEfficiency] = useState([]);
    const [averageScoreEnvironmImp, setAverageScoreEnvironmImp] = useState([]);

    // const [resultBackend, setResultBackend] = useState(options.result); // einzelnes Element aus Array zuweisen
    const [axiosResponse, setAxiosResponse] = useState([]);
    const [loadAnalysis, setLoadAnalysis] = useState(false);
    const [analysisIsReadyToShow, setAnalysisIsReadyToShow] = useState(false);

   // const [count, setCount] = useState(0);
    var DisableButton = false;
    const [state, setState] = React.useState({
        checkedF: false, 
    });
    //console.log("state.checkedF" + state.checkedF)
    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };




    // (SwitchValues_Methods) Methoden für Status der aktiven Benutzer-Auswahl für alle Artikel
    //Articel: Scenarios:
    if (categoryRadioScenario === "Custom") {
        finalValueQuality = categoryQualityCustom;
        finalValueFeedFlex = categoryFeedFlexCustom;
        finalValueOperation = categoryOperationCustom;
        finalValueMaintenance = categoryMaintenanceCustom;
        finalValueEfficiency = categoryEfficiencyCustom;
        finalValueEnvironment = categoryEnvironmentCustom;
    } else {
        finalValueQuality = categoryQuality;
        finalValueFeedFlex = categoryFeedFlex;
        finalValueOperation = categoryOperation;
        finalValueMaintenance = categoryMaintenance;
        finalValueEfficiency = categoryEfficiency;
        finalValueEnvironment = categoryEnvironment;
    }
    /////////////////////////////////////////////////////////////////////////////////////////FINAL VALUES:
    // Articel: Scenario-Details:
    if (criteriaRadioScenario === true) {
        finalValuePsd = psd;
        finalValueMaxFineness = maxFineness;
        finalValueDehydration = dehydration;
        finalValueDrying = drying;
        finalValueMaxFeedSize = maxFeedSize;
        finalValueProductChangingTime = productChangingTime;
        finalValueUnderstandingOfProcess = understandingOfProcess;
        finalValueSensitivityOfProcess = sensitivityOfProcess;
        finalValueControlabilityPersonnel = controlabilityPersonnel;
        finalValueAvailableExperience = availableExperience;
        finalValueThroughput = throughput;
        finalValueMaintenanceTime = maintenanceTime;
        finalValueComplexityOfMaintenance = complexityOfMaintenance;
        finalValueWearCosts = wearCosts;
        finalValueEnergyDemand = energyDemand;
        finalValueWaterDemand = waterDemand;
        finalValueDustEmissions = dustEmissions;
        finalValueNoise = noise;
        finalValueEffectOfVibration = effectOfVibration;
    } else {
        //manuell, da es nur eine Preset für Scenario Details gibt:
        finalValuePsd = 0.35;
        finalValueMaxFineness = 0.3;
        finalValueDehydration = 0.2;
        finalValueDrying = 0.15;
        finalValueMaxFeedSize = 0.6;
        finalValueProductChangingTime = 0.4;
        finalValueUnderstandingOfProcess = 0.05;
        finalValueSensitivityOfProcess = 0.25;
        finalValueControlabilityPersonnel = 0.2;
        finalValueAvailableExperience = 0.2;
        finalValueThroughput = 0.3;
        finalValueMaintenanceTime = 0.4;
        finalValueComplexityOfMaintenance = 0.2;
        finalValueWearCosts = 0.4;
        finalValueEnergyDemand = 0.95;
        finalValueWaterDemand = 0.05;
        finalValueDustEmissions = 0.3;
        finalValueNoise = 0.5;
        finalValueEffectOfVibration = 0.2;
    }

    // Logik, damit der Wert 0 in die Rechnung übernommen wird:
    if (finalValueQuality === 0) {
        finalValueQuality = 0.001;
    } else {
    }
    if (finalValueFeedFlex === 0) {
        finalValueFeedFlex = 0.001;
    } else {
    }
    if (finalValueOperation === 0) {
        finalValueOperation = 0.001;
    } else {
    }
    if (finalValueMaintenance === 0) {
        finalValueMaintenance = 0.001;
    } else {
    }
    if (finalValueEfficiency === 0) {
        finalValueEfficiency = 0.001;
    } else {
    }
    if (finalValueEnvironment === 0) {
        finalValueEnvironment = 0.001;
    } else {
    }

    if (finalValuePsd === 0) {
        finalValuePsd = 0.001;
    } else {
    }
    if (finalValueMaxFineness === 0) {
        finalValueMaxFineness = 0.001;
    } else {
    }
    if (finalValueDehydration === 0) {
        finalValueDehydration = 0.001;
    } else {
    }
    if (finalValueDrying === 0) {
        finalValueDrying = 0.001;
    } else {
    }
    if (finalValueMaxFeedSize === 0) {
        finalValueMaxFeedSize = 0.001;
    } else {
    }
    if (finalValueProductChangingTime === 0) {
        finalValueProductChangingTime = 0.001;
    } else {
    }
    if (finalValueUnderstandingOfProcess === 0) {
        finalValueUnderstandingOfProcess = 0.001;
    } else {
    }
    if (finalValueSensitivityOfProcess === 0) {
        finalValueSensitivityOfProcess = 0.001;
    } else {
    }
    if (finalValueControlabilityPersonnel === 0) {
        finalValueControlabilityPersonnel = 0.001;
    } else {
    }
    if (finalValueAvailableExperience === 0) {
        finalValueAvailableExperience = 0.001;
    } else {
    }
    if (finalValueThroughput === 0) {
        finalValueThroughput = 0.001;
    } else {
    }
    if (finalValueMaintenanceTime === 0) {
        finalValueMaintenanceTime = 0.001;
    } else {
    }
    if (finalValueComplexityOfMaintenance === 0) {
        finalValueComplexityOfMaintenance = 0.001;
    } else {
    }
    if (finalValueWearCosts === 0) {
        finalValueWearCosts = 0.001;
    } else {
    }
    if (finalValueEnergyDemand === 0) {
        finalValueEnergyDemand = 0.001;
    } else {
    }
    if (finalValueWaterDemand === 0) {
        finalValueWaterDemand = 0.001;
    } else {
    }
    if (finalValueDustEmissions === 0) {
        finalValueDustEmissions = 0.001;
    } else {
    }
    if (finalValueNoise === 0) {
        finalValueNoise = 0.001;
    } else {
    }
    if (finalValueEffectOfVibration === 0) {
        finalValueEffectOfVibration = 0.001;
    } else {
    }

    //Axios Formular:
    let triggerAxios = () => {
        setAnalysisIsReadyToShow(false);
        setInspectMill("undefined")
        setFinalList([])
        const text = "löse Funktion >>triggerAxios<< aus";
        const axios = require("axios");
        let data = JSON.stringify({
            typeOfApplication: finalValueTypeOfApp,
            scenarioStatus: categoryRadioScenario,
            comparisonMills: finalMillsID,
            millSelectStatus: millSelectRadioStatus,
            inputPresets: [
                {
                    category: "Quality",
                    criteria: "KGV_SC",
                    categoriesImportance: finalValueQuality,
                    criteriaImportance: finalValuePsd,
                    maximumWeights: 7,
                    thresholds: 5,
                },
                {
                    category: "Quality",
                    criteria: "Feinheit_SC",
                    categoriesImportance: finalValueQuality,
                    criteriaImportance: finalValueMaxFineness,
                    maximumWeights: 8,
                    thresholds: 3,
                },
                {
                    category: "Quality",
                    criteria: "Dehydration_SC",
                    categoriesImportance: finalValueQuality,
                    criteriaImportance: finalValueDehydration,
                    maximumWeights: 7,
                    thresholds: 1,
                },
                {
                    category: "Quality",
                    criteria: "Trocknen_SC",
                    categoriesImportance: finalValueQuality,
                    criteriaImportance: finalValueDrying,
                    maximumWeights: 7,
                    thresholds: 3,
                },
                {
                    category: "Feed/Flexibility",
                    criteria: "MaxAufgabekorngroesse_SC",
                    categoriesImportance: finalValueFeedFlex,
                    criteriaImportance: finalValueMaxFeedSize,
                    maximumWeights: 8,
                    thresholds: 3,
                },
                {
                    category: "Feed/Flexibility",
                    criteria: "Wechselzeit_SC",
                    categoriesImportance: finalValueFeedFlex,
                    criteriaImportance: finalValueProductChangingTime,
                    maximumWeights: 7,
                    thresholds: 1,
                },
                {
                    category: "Operation",
                    criteria: "ProzessVerstaendniss_SC",
                    categoriesImportance: finalValueOperation,
                    criteriaImportance: finalValueUnderstandingOfProcess,
                    maximumWeights: 8,
                    thresholds: 1,
                },
                {
                    category: "Operation",
                    criteria: "Sensibilitaet_SC",
                    categoriesImportance: finalValueOperation,
                    criteriaImportance: finalValueSensitivityOfProcess,
                    maximumWeights: 7,
                    thresholds: 1,
                },
                {
                    category: "Operation",
                    criteria: "Kontrollierbarkeit_SC",
                    categoriesImportance: finalValueOperation,
                    criteriaImportance: finalValueControlabilityPersonnel,
                    maximumWeights: 7,
                    thresholds: 1,
                },
                {
                    category: "Operation",
                    criteria: "Erfahrung_SC",
                    categoriesImportance: finalValueOperation,
                    criteriaImportance: finalValueAvailableExperience,
                    maximumWeights: 7,
                    thresholds: 1,
                },
                {
                    category: "Operation",
                    criteria: "Durchsatz_SC",
                    categoriesImportance: finalValueOperation,
                    criteriaImportance: finalValueThroughput,
                    maximumWeights: 9,
                    thresholds: 4,
                },
                {
                    category: "Maintenance",
                    criteria: "Instandhaltungszeit_SC",
                    categoriesImportance: finalValueMaintenance,
                    criteriaImportance: finalValueMaintenanceTime,
                    maximumWeights: 7,
                    thresholds: 1,
                },
                {
                    category: "Maintenance",
                    criteria: "Instandhaltung_SC",
                    categoriesImportance: finalValueMaintenance,
                    criteriaImportance: finalValueComplexityOfMaintenance,
                    maximumWeights: 7,
                    thresholds: 1,
                },
                {
                    category: "Maintenance",
                    criteria: "Verschleisskosten_SC",
                    categoriesImportance: finalValueMaintenance,
                    criteriaImportance: finalValueWearCosts,
                    maximumWeights: 7,
                    thresholds: 4,
                },
                {
                    category: "Efficiency",
                    criteria: "Energiebedarf_SC",
                    categoriesImportance: finalValueEfficiency,
                    criteriaImportance: finalValueEnergyDemand,
                    maximumWeights: 8,
                    thresholds: 3,
                },
                {
                    category: "Efficiency",
                    criteria: "Wasserbedarf_SC",
                    categoriesImportance: finalValueEfficiency,
                    criteriaImportance: finalValueWaterDemand,
                    maximumWeights: 7,
                    thresholds: 1,
                },
                {
                    category: "Environmental_Impact",
                    criteria: "Staubemissionen_SC",
                    categoriesImportance: finalValueEnvironment,
                    criteriaImportance: finalValueDustEmissions,
                    maximumWeights: 7,
                    thresholds: 1,
                },
                {
                    category: "Environmental_Impact",
                    criteria: "Laerm_SC",
                    categoriesImportance: finalValueEnvironment,
                    criteriaImportance: finalValueNoise,
                    maximumWeights: 7,
                    thresholds: 1,
                },
                {
                    category: "Environmental_Impact",
                    criteria: "Schwingungseinwirkung_SC",
                    categoriesImportance: finalValueEnvironment,
                    criteriaImportance: finalValueEffectOfVibration,
                    maximumWeights: 7,
                    thresholds: 1,
                },
            ],
        });
        let config = {
            method: "post",
            url: "/api/mills/analyze/all",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };
        axios(config)
            .then((response) => {
                ////console.log(JSON.stringify(response.data)); // zeig Werte der Backend Rechnung
                setAxiosResponse(response.data);
                setOptions(Object.keys(data).map((result) => data[result]));
               
                if (response.data !== null) { // TODO
                    //console.log("response.data" + response.data);
                    setAnalysisIsReadyToShow(true);
                }
            })
            .catch((error) => {
                //console.log(error);
            });




        setInspectMill(finalList[0][0]);
        try {


            setFinalScore([axiosResponse.map((item) => item.finalScore)]); //item.finalScore
            setFinalList([axiosResponse.map((item) => item.millname)]);

            setAverageScoreQuality([
                axiosResponse.map(
                    (item) =>
                        (
                            (item.result.ballMillComparison.quality
                                .comparisonResult +
                                item.result.verticalMillComparison.quality
                                    .comparisonResult +
                                item.result.rollerPressMillComparison.quality
                                    .comparisonResult +
                                item.result.hororMillComparison.quality
                                    .comparisonResult) /
                            4
                        ).toFixed(1),
                    2
                ),
            ]);
            setAverageScoreFeed([
                axiosResponse.map(
                    (item) =>
                        (
                            (item.result.ballMillComparison.feed.comparisonResult +
                                item.result.verticalMillComparison.feed
                                    .comparisonResult +
                                item.result.rollerPressMillComparison.feed
                                    .comparisonResult +
                                item.result.hororMillComparison.feed
                                    .comparisonResult) /
                            4
                        ).toFixed(1),
                    2
                ),
            ]);

            setAverageScoreOperation([
                axiosResponse.map(
                    (item) =>
                        (
                            (item.result.ballMillComparison.operation
                                .comparisonResult +
                                item.result.verticalMillComparison.operation
                                    .comparisonResult +
                                item.result.rollerPressMillComparison.operation
                                    .comparisonResult +
                                item.result.hororMillComparison.operation
                                    .comparisonResult) /
                            4
                        ).toFixed(1),
                    2
                ),
            ]);

            setAverageScoreMaintenance([
                axiosResponse.map(
                    (item) =>
                        (
                            (item.result.ballMillComparison.maintenance
                                .comparisonResult +
                                item.result.verticalMillComparison.maintenance
                                    .comparisonResult +
                                item.result.rollerPressMillComparison.maintenance
                                    .comparisonResult +
                                item.result.hororMillComparison.maintenance
                                    .comparisonResult) /
                            4
                        ).toFixed(1),
                    2
                ),
            ]);

            setAverageScoreEfficiency([
                axiosResponse.map(
                    (item) =>
                        (
                            (item.result.ballMillComparison.efficiency
                                .comparisonResult +
                                item.result.verticalMillComparison.efficiency
                                    .comparisonResult +
                                item.result.rollerPressMillComparison.efficiency
                                    .comparisonResult +
                                item.result.hororMillComparison.efficiency
                                    .comparisonResult) /
                            4
                        ).toFixed(1),
                    2
                ),
            ]);

            setAverageScoreEnvironmImp([
                axiosResponse.map(
                    (item) =>
                        (
                            (item.result.ballMillComparison.environmentalImpact
                                .comparisonResult +
                                item.result.verticalMillComparison
                                    .environmentalImpact.comparisonResult +
                                item.result.rollerPressMillComparison
                                    .environmentalImpact.comparisonResult +
                                item.result.hororMillComparison.environmentalImpact
                                    .comparisonResult) /
                            4
                        ).toFixed(1),
                    2
                ),
            ]);


        } catch {

        }
    };

   


    let triggerExcelFile = () => {
        //const text = "löse Funktion >>triggerAxios<< aus";
        const axios = require("axios");
        let data = JSON.stringify({
            typeOfApplication: finalValueTypeOfApp,
            scenarioStatus: categoryRadioScenario,
            comparisonMills: finalMillsID,
            millSelectStatus: millSelectRadioStatus, 
            inputPresets: [
                {
                    category: "Quality",
                    criteria: "KGV_SC",
                    categoriesImportance: finalValueQuality,
                    criteriaImportance: finalValuePsd,
                    maximumWeights: 7,
                    thresholds: 5,
                },
                {
                    category: "Quality",
                    criteria: "Feinheit_SC",
                    categoriesImportance: finalValueQuality,
                    criteriaImportance: finalValueMaxFineness,
                    maximumWeights: 8,
                    thresholds: 3,
                },
                {
                    category: "Quality",
                    criteria: "Dehydration_SC",
                    categoriesImportance: finalValueQuality,
                    criteriaImportance: finalValueDehydration,
                    maximumWeights: 7,
                    thresholds: 1,
                },
                {
                    category: "Quality",
                    criteria: "Trocknen_SC",
                    categoriesImportance: finalValueQuality,
                    criteriaImportance: finalValueDrying,
                    maximumWeights: 7,
                    thresholds: 3,
                },
                {
                    category: "Feed/Flexibility",
                    criteria: "MaxAufgabekorngroesse_SC",
                    categoriesImportance: finalValueFeedFlex,
                    criteriaImportance: finalValueMaxFeedSize,
                    maximumWeights: 8,
                    thresholds: 3,
                },
                {
                    category: "Feed/Flexibility",
                    criteria: "Wechselzeit_SC",
                    categoriesImportance: finalValueFeedFlex,
                    criteriaImportance: finalValueProductChangingTime,
                    maximumWeights: 7,
                    thresholds: 1,
                },
                {
                    category: "Operation",
                    criteria: "ProzessVerstaendniss_SC",
                    categoriesImportance: finalValueOperation,
                    criteriaImportance: finalValueUnderstandingOfProcess,
                    maximumWeights: 8,
                    thresholds: 1,
                },
                {
                    category: "Operation",
                    criteria: "Sensibilitaet_SC",
                    categoriesImportance: finalValueOperation,
                    criteriaImportance: finalValueSensitivityOfProcess,
                    maximumWeights: 7,
                    thresholds: 1,
                },
                {
                    category: "Operation",
                    criteria: "Kontrollierbarkeit_SC",
                    categoriesImportance: finalValueOperation,
                    criteriaImportance: finalValueControlabilityPersonnel,
                    maximumWeights: 7,
                    thresholds: 1,
                },
                {
                    category: "Operation",
                    criteria: "Erfahrung_SC",
                    categoriesImportance: finalValueOperation,
                    criteriaImportance: finalValueAvailableExperience,
                    maximumWeights: 7,
                    thresholds: 1,
                },
                {
                    category: "Operation",
                    criteria: "Durchsatz_SC",
                    categoriesImportance: finalValueOperation,
                    criteriaImportance: finalValueThroughput,
                    maximumWeights: 9,
                    thresholds: 4,
                },
                {
                    category: "Maintenance",
                    criteria: "Instandhaltungszeit_SC",
                    categoriesImportance: finalValueMaintenance,
                    criteriaImportance: finalValueMaintenanceTime,
                    maximumWeights: 7,
                    thresholds: 1,
                },
                {
                    category: "Maintenance",
                    criteria: "Instandhaltung_SC",
                    categoriesImportance: finalValueMaintenance,
                    criteriaImportance: finalValueComplexityOfMaintenance,
                    maximumWeights: 7,
                    thresholds: 1,
                },
                {
                    category: "Maintenance",
                    criteria: "Verschleisskosten_SC",
                    categoriesImportance: finalValueMaintenance,
                    criteriaImportance: finalValueWearCosts,
                    maximumWeights: 7,
                    thresholds: 4,
                },
                {
                    category: "Efficiency",
                    criteria: "Energiebedarf_SC",
                    categoriesImportance: finalValueEfficiency,
                    criteriaImportance: finalValueEnergyDemand,
                    maximumWeights: 8,
                    thresholds: 3,
                },
                {
                    category: "Efficiency",
                    criteria: "Wasserbedarf_SC",
                    categoriesImportance: finalValueEfficiency,
                    criteriaImportance: finalValueWaterDemand,
                    maximumWeights: 7,
                    thresholds: 1,
                },
                {
                    category: "Environmental_Impact",
                    criteria: "Staubemissionen_SC",
                    categoriesImportance: finalValueEnvironment,
                    criteriaImportance: finalValueDustEmissions,
                    maximumWeights: 7,
                    thresholds: 1,
                },
                {
                    category: "Environmental_Impact",
                    criteria: "Laerm_SC",
                    categoriesImportance: finalValueEnvironment,
                    criteriaImportance: finalValueNoise,
                    maximumWeights: 7,
                    thresholds: 1,
                },
                {
                    category: "Environmental_Impact",
                    criteria: "Schwingungseinwirkung_SC",
                    categoriesImportance: finalValueEnvironment,
                    criteriaImportance: finalValueEffectOfVibration,
                    maximumWeights: 7,
                    thresholds: 1,
                },
            ],
        });
        const FileDownload = require("js-file-download");
        let config = {
            method: "post",
            responseType: "blob",
            url: "api/download",
            headers: {
                "Content-Type": "application/json",
                // "Content-Disposition": "attachment;filename=ExcelReport.xls",
                // "Content-Type": "application/ms-excel",
            },
            data: data,
        };
        axios(config)
            .then((response) => {
                //console.log("response.data" + response.data.length);
                var currentdate = new Date(); 
                FileDownload(response.data, ["FGT_AHP_" + yyyymmddhhmm(currentdate)+"_" + finalValueTypeOfApp+"_"+categoryRadioScenario+".xlsx"]);
               
                 

            })
            .catch((error) => {
                //console.log(error);
            });
    };

    // Avarages Logic
    useEffect(() => {

        try {


            setFinalScore([axiosResponse.map((item) => item.finalScore)]); //item.finalScore
            setFinalList([axiosResponse.map((item) => item.millname)]);

            setAverageScoreQuality([
                axiosResponse.map(
                    (item) =>
                        (
                            (item.result.ballMillComparison.quality
                                .comparisonResult +
                                item.result.verticalMillComparison.quality
                                    .comparisonResult +
                                item.result.rollerPressMillComparison.quality
                                    .comparisonResult +
                                item.result.hororMillComparison.quality
                                    .comparisonResult) /
                            4
                        ).toFixed(1),
                    2
                ),
            ]);
            setAverageScoreFeed([
                axiosResponse.map(
                    (item) =>
                        (
                            (item.result.ballMillComparison.feed.comparisonResult +
                                item.result.verticalMillComparison.feed
                                    .comparisonResult +
                                item.result.rollerPressMillComparison.feed
                                    .comparisonResult +
                                item.result.hororMillComparison.feed
                                    .comparisonResult) /
                            4
                        ).toFixed(1),
                    2
                ),
            ]);

            setAverageScoreOperation([
                axiosResponse.map(
                    (item) =>
                        (
                            (item.result.ballMillComparison.operation
                                .comparisonResult +
                                item.result.verticalMillComparison.operation
                                    .comparisonResult +
                                item.result.rollerPressMillComparison.operation
                                    .comparisonResult +
                                item.result.hororMillComparison.operation
                                    .comparisonResult) /
                            4
                        ).toFixed(1),
                    2
                ),
            ]);

            setAverageScoreMaintenance([
                axiosResponse.map(
                    (item) =>
                        (
                            (item.result.ballMillComparison.maintenance
                                .comparisonResult +
                                item.result.verticalMillComparison.maintenance
                                    .comparisonResult +
                                item.result.rollerPressMillComparison.maintenance
                                    .comparisonResult +
                                item.result.hororMillComparison.maintenance
                                    .comparisonResult) /
                            4
                        ).toFixed(1),
                    2
                ),
            ]);

            setAverageScoreEfficiency([
                axiosResponse.map(
                    (item) =>
                        (
                            (item.result.ballMillComparison.efficiency
                                .comparisonResult +
                                item.result.verticalMillComparison.efficiency
                                    .comparisonResult +
                                item.result.rollerPressMillComparison.efficiency
                                    .comparisonResult +
                                item.result.hororMillComparison.efficiency
                                    .comparisonResult) /
                            4
                        ).toFixed(1),
                    2
                ),
            ]);

            setAverageScoreEnvironmImp([
                axiosResponse.map(
                    (item) =>
                        (
                            (item.result.ballMillComparison.environmentalImpact
                                .comparisonResult +
                                item.result.verticalMillComparison
                                    .environmentalImpact.comparisonResult +
                                item.result.rollerPressMillComparison
                                    .environmentalImpact.comparisonResult +
                                item.result.hororMillComparison.environmentalImpact
                                    .comparisonResult) /
                            4
                        ).toFixed(1),
                    2
                ),
            ]);


        } catch {

        }


        }, [axiosResponse]);

    function yyyymmddhhmm(dateIn) {
        var yyyy = dateIn.getFullYear();
        var mm = dateIn.getMonth() + 1; // getMonth() is zero-based
        var dd = dateIn.getDate();
        var h = dateIn.getHours();
        if (h < 10) {
            h = ["0" + h];
        }
        var m = dateIn.getMinutes();    
        if (m < 10) {
            m = ["0" + m];
        }


        return String(10000 * yyyy + 100 * mm + dd+"-"+h+m); // Leading zeros for mm and dd
    }



    // Ladebalken für jede Berechnung
    let contentLoadingAct;
    const [open, setOpen] = useState(false);
    const triggerLoadingProzess = () => {
        setOpen(!open);
    };
    let triggerLoadingAct = () => {
        contentLoadingAct = (
            <section class="features">
                <Backdrop
                    className={classes.backdrop}
                    open={open}
                    transitionDuration={425}
                >
                    <div className={classes.root2}>
                        <Paper elevation={24} square={false}>
                            <hr />
                            <h3>  Loading ... </h3>
                            <hr /> <br />
                            <div
                                style={{
                                    alignItems: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <CircularProgress
                                    size="6rem"
                                    thickness="6"
                                    value={progress}
                                    valueBuffer={buffer}
                                >
                                    {" "}
                                </CircularProgress>
                                <br />
                            </div>
                            <br />
                        </Paper>
                    </div>
                </Backdrop>
            </section>
        );
    };

    // Continue Button ausblenden, wenn Werte aus Scenarios und Scenario-Details gößer als 100% sind
    let contentA;
    let contentB; 
    
        
     //   DisableButton = false;
       // if (categoryRadioScenario === "Custom") {

        if (limitOfSliderCriteria === true && limitOfSliderCategorie === true && categoryRadioScenario === "Custom") {
                contentA = (
                    <p style={{ color: "red" }}><br /><br />

                The sum of the categories and criterias in one or more category is not 100%.
                        <br />


                        <FormControlLabel
                            control={<Checkbox
                                checked={state.checkedF}
                                onChange={handleChange}
                                name="checkedF"
                            />}
                            label="Normalise scenario- and criteria-importance"
                        />
                    </p>
                );
                DisableButton = true;
                contentB = <p> </p>;
            } else {

            DisableButton = false;

                if (limitOfSliderCategorie === true && categoryRadioScenario === "Custom") {
                    contentA = (
                        <p style={{ color: "red" }} align="center"><br /><br /><center>
                            The sum of the categories is not 100%.
                <br />


                            <FormControlLabel
                                control={<Checkbox
                                    checked={state.checkedF}
                                    onChange={handleChange}
                                    name="checkedF"
                                />}
                                label="Normalise scenario-importance"
                            /></center>
                        </p>
                    );
                    DisableButton = true;
                    normalise = true;
                } else {
                    contentA = (<p> </p>);
                    DisableButton = false;
                    normalise = false;
                    //console.log("DisableButton" + DisableButton);
                }
                //console.log("DisableButton" + DisableButton)
                if (limitOfSliderCriteria === true && categoryRadioScenario === "Custom") {
                    contentB = (
                        <p style={{ color: "red" }}><br /><br />
                The sum of the criterias in one or more category is not 100%.
                            <br />
                            <FormControlLabel
                                control={<Checkbox
                                    checked={state.checkedF}
                                    onChange={handleChange}
                                    name="checkedF"
                                />}
                                label="Normalise criteria-importance"
                            />
                        </p>
                    );
                    DisableButton = true;
                    normalise = true;
                } else {
                    contentB = (<p> </p>);
                    DisableButton = false;
                    normalise = false;
                    //console.log("DisableButton" + DisableButton);
                }


            } 

       // } 
    
    // Switch Value für Artikel: Mill Selection.
    if (millSelectRadioStatus === "all" || allMillsWithoutFilters === true) {
        finalMillsID = [
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
            20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36,
            37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53,
            54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67
        ];
    } else if (
        millSelectRadioStatus === "individual" &&
        allMillsWithoutFilters === false
    ) {
        finalMillsID = millSelectionUserChoice;
    }

    let warntextMillSelection;
    if (millSelectRadioStatus === "") {
        DisableButton = true;
        warntextMillSelection =
            "You have not selected a mill, please enter a mill in Articel >>Mill Selection<< for comparison";
    } else {
        DisableButton = false;

    }

    // BarChart JS
    let scaleBarChart_Mini = {
        yAxes: [
            {
                ticks: {
                    display: true,
                    autoSkip: false,
                    maxTicksLimit: 10,
                    beginAtZero: true,
                    suggestedMax: 100,
                },
                gridLines: {
                    display: true,
                },
            },
        ],
        xAxes: [
            {
                categoryPercentage: 0.5, // Abstand zwischen den Balken -> Warum keine Auswirkung?
                gridLines: {
                    display: false,
                    offsetGridLines: false,
                },
            },
        ],
    };

    //Button Switch -> Show Graphics
    const [checked, setChecked] = useState(false);

    let triggerContenChartsandGraphics = () => {
        setChecked(true);
        if (checked === true) {
            contenChartsandGraphics = <p> Hallo</p>;
        } else {
            contenChartsandGraphics = null;
        }
    };

    const [progress, setProgress] = useState(0);
    const [buffer, setBuffer] = useState(10);
    const progressRef = React.useRef(() => {});

    useEffect(() => {
        if (loadAnalysis == true) {
            progressRef.current = () => {
                if (progress > 100) {
                    setProgress(0);
                    setBuffer(10);
                }
                // else {
                //     const diff = Math.random() * 10;
                //     const diff2 = Math.random() * 10;
                //     setProgress(progress + diff);
                //     setBuffer(progress + diff + diff2);
                // }
                if (progress >= 100) {
                    setLoadAnalysis(false);
                    ////console.log("progress war bei 100");
                }
                if (analysisIsReadyToShow == true) {
                    ////console.log("Axios war schneller fertig");
                    setProgress(100);
                }
            };
        } else {
            progressRef.current = () => {};
        }
        // Falls das mal jemand weiterbearbeitet, sorry für das folgende...
        if ((limitOfSliderCriteria === true && limitOfSliderCategorie === true) || (limitOfSliderCriteria == "" && limitOfSliderCategorie == "") || (limitOfSliderCriteria == "" && limitOfSliderCategorie == true) || (limitOfSliderCriteria == true && limitOfSliderCategorie == "") || state.checkedF == true) {
            DisableButton = false;
        } else {
            DisableButton = true;
        }


        //console.log("DisableButton" + DisableButton)

        //console.log("limitOfSliderCriteria" + limitOfSliderCriteria)

        //console.log("limitOfSliderCategorie" + limitOfSliderCategorie)

    });

    useEffect(() => {
        const timer = setInterval(() => {
            progressRef.current();
        }, 1400); // Dauer, bis der Ladebalken fertig ist

        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        // UseEffect -> nur für console Log Ausgabe
        // //console.log("inspectMill", inspectMill);
        // //console.log("AxiosResponse", axiosResponse);
        // //console.log("AxiosResponse.millname", axiosResponse.millname);
    }, [inspectMill, axiosResponse, options]);

    let contenChartsandGraphics; // gibt den gesamten Kontext an Grafik wieder

    let finalListAsObject = finalList.map((item) => {
        let objectArray = Object.entries(item);
        //Lösungsansatz aus:
        //https://stackoverflow.com/questions/42974735/create-object-from-array/42974762

        let objectMapping = objectArray.map(
            (elements) =>
                (elements = Object.assign(
                    {},
                    elements.reduce((acc, cur) => ({
                        Millname: cur,
                        keys: parseInt(acc),
                    }))
                ))
        );

        return objectMapping;
    });


    //console.log("inspectMill"+ inspectMill)
    if (finalListAsObject != null && finalListAsObject != "") {

        var linkToDB = ""
        //console.log(" finalListAsObject" + finalListAsObject[0][0]["Millname"])
        if (inspectMill == null || inspectMill == "" || inspectMill == "undefined") {
        var linkToDB = "/technologydatabase?name=" + finalListAsObject[0][0]["Millname"];

        }
        if (inspectMill != null && inspectMill != "" && inspectMill != "undefined")        {
            var linkToDB = "/technologydatabase?name=" + inspectMill;
    }
        //console.log("inspectMill" + inspectMill)

    }

    if (
        checked === true &&
        analysisIsReadyToShow === true &&
        loadAnalysis === false
    ) {

        var diagramHeight = Math.ceil(finalList[0].length * 10);

        if (finalList[0].length<10) {
            diagramHeight = 100;
        }
         


        contenChartsandGraphics = (
            <div>
                <br />
                <br />
                <article>
                    <section>
                        <h2>Final Ranking</h2>
                        <br />
                        <hr />
                        <p>
                            Total number of technologies involved in
                            calculation: {finalList[0].length} out of 67
                        </p>
                        <p>
                            Type of Application: {typeOfAppLabel} (
                            {FilterStatus})
                        </p>
                        {/* //finalValueTypeOfApp */}
                        <p>Scenario : {categoryRadioScenario}</p>
                        <br />
                        <Button
                            className={classes.root}
                            variant="contained"
                            //fullWidth="true"
                            size="large"
                            startIcon={<CloudUploadIcon />}
                            onClick={() => {
                                //setLoadAnalysis(true);
                               // setProgress(0); //achtung du benötigst neuen Set für Loading ExcelFile nicht von Axios "Start Button"
                                //setBuffer(10);
                                triggerExcelFile();
                              //  triggerLoadingProzess();
                              //  triggerLoadingAct();
                            }}
                        >
                            Download Excel File
                        </Button>
                        <hr />
                        <HorizontalBar
                            type="horizontalBar"
                            data={{
                                labels: finalList[0], // Werte für Y-Achse (finalMillSelectionCountMill)
                                datasets: [
                                    {
                                        data: finalScore[0], // Werte für X-Achse
                                        borderWidth: 3,
                                        maxBarThickness: 50,
                                        //minBarThickness: 20,
                                        //barThickness: 30,
                                        //minBarLength: 30,
                                        //backgroundColor: "rgba(54, 162, 235, 0.5)",
                                        //borderColor: "rgb(54, 162, 235)",
                                        backgroundColor:
                                            'rgba(0, 87, 157,1)',
                                        borderColor: 'rgb(0, 87, 157)',
                                    },
                                ],
                            }}
                            height={diagramHeight}
                            //height={null}
                            //width={null}
                            options={{
                                aspectRatio: 1, //Verhältnis Height zu Width
                                responsive: true,
                                maintainAspectRatio: true, // IMPORTANt: if false, it will crash because there will be no more limits for Height.
                                scales: {
                                    yAxes: [
                                        {
                                            ticks: {
                                                autoSkip: true,
                                                //maxTicksLimit: 10,
                                                beginAtZero: true,
                                                suggestedMax: 100,
                                                fontSize: 14,
                                                padding: 0, // distance between Text and Axis
                                            },
                                            gridLines: {
                                                display: false,
                                                offsetGridLines: true,
                                            },
                                        },
                                    ],
                                    xAxes: [
                                        {
                                            scaleLabel: {
                                                display: true,
                                                labelString: "Final Score in %",
                                            },

                                            ticks: {
                                                stepSize: 10,
                                                autoSkip: false,
                                                beginAtZero: true,
                                                suggestedMax: 100,
                                            },
                                            gridLines: {
                                                display: true,
                                                offsetGridLines: false,
                                                //color: "red",
                                                borderColor:
                                                    "rgb(54, 162, 235)",
                                                tickColor:
                                                    "rgba(54, 162, 235, 0.5)",
                                            },
                                        },
                                    ],
                                },
                                title: {
                                    display: true,
                                    text: "Final Ranking in %",
                                    fontSize: 25,
                                },
                                legend: {
                                    display: false,
                                    position: "bottom",
                                },
                            }}
                            getOptionLabel={(option) =>
                                option.Millname.toString() //+ //console.log("")
                            }
                        />
                        <br /> <hr />
                        <table width="100%">
                            <tr>
                                <td>
                                    <Autocomplete
                                        disableClearable
                                        id="Inspect Mills"
                                        options={finalListAsObject[0]} //ACHTUNG: finalListAsObject[0] muss geöffnet werden ansonsten sind die Objekte nicht zu finden für statische Werte: millSelectionCountMill
                                        getOptionLabel={(option) =>
                                            option.Millname
                                        } //option.Millname
                                        defaultValue={finalListAsObject[0][0]} 
                                        onClick
                                        onChange={(event, value) => {
                                            setMillSelek(value.keys);
                                            setInspectMill(value.Millname);
                                        }}
                                        style={{ width: 300 }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Inspect results" 
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </td>
                                <td align="right"> <a target="_blank" href={linkToDB}>Go to Database</a>
                                </td>
                                <td align="right">
                                    <span>
                                        <a target="_blank" href={linkToDB}>
                                            <span> <IconSearch></IconSearch></span>
                                            </a>
                                    </span>
                                </td>{" "}
                            </tr>{" "}
                        </table>
                        <Radar
                            data={{
                                labels: [
                                    "Quality",
                                    "Feed / Flexibility",
                                    "Operation",
                                    "Maintenance",
                                    "Efficiency",
                                    "Environmental Impact",
                                ],

                                categoryPercentage: "flex",
                                datasets: [
                                    {
                                        data: [
                                            averageScoreQuality[0][millSelek],
                                            averageScoreFeed[0][millSelek],
                                            averageScoreOperation[0][millSelek],
                                            averageScoreMaintenance[0][
                                                millSelek
                                            ],
                                            averageScoreEfficiency[0][
                                                millSelek
                                            ],
                                            averageScoreEnvironmImp[0][
                                                millSelek
                                            ],
                                        ],
                                        maxBarThickness: 50,
                                        minBarThickness: 20,
                                        barThickness: 30,
                                        minBarLength: 30,
                                        backgroundColor: [
                                            'rgba(0, 87, 157,0.3)',
                                        ],
                                        borderColor: 'rgb(0, 87, 157)',
                                        pointBackgroundColor:
                                            'rgb(0, 87, 157)',
                                        pointBorderColor: "#fff",
                                        pointHoverBackgroundColor: "#fff",
                                        pointHoverBorderColor:
                                            "rgb(0, 87, 157)",
                                    },
                                ],
                            }}
                            options={{
                                scale: {
                                    pointLabels: {
                                        fontSize: 18,
                                    },
                                    angleLines: {
                                        display: false,
                                    },
                                    ticks: {
                                        suggestedMin: 50,
                                        //  ticks: [0,20,40,50,60,80,100],
                                        suggestedMax: 100,
                                        display: true,
                                        autoSkip: true,
                                        maxTicksLimit: 10,
                                        beginAtZero: true,
                                        fontSize: 16,
                                    },
                                },
                                title: {
                                    display: true,
                                    text: "Category Score in %",
                                    fontSize: 25,
                                },
                                legend: {
                                    display: false,
                                    position: "bottom",
                                },
                            }}
                        ></Radar>
                    </section>
                </article>
            </div>
        );
        DisableButton = true;
    } else {
        contenChartsandGraphics = <div></div>;
    }

    if (loadAnalysis === true) {
        triggerLoadingAct();
    } else {
    }
    let textFinishedLoading;
    if (
        checked === true &&
        analysisIsReadyToShow === true &&
        loadAnalysis === false
    ) {
        textFinishedLoading = <div></div>;
    }



    var ButtonEvalText;
    ButtonEvalText = "Start evaluation";
 

    var disabledStyle;
    if (DisableButton == true) {
        disabledStyle = (
            {  color: 'red' }
        )
    }

    return (
        <div> 
            <br />
            <br />

            <article>
                <section class="features">
                    <ul class="features">
                        <li></li>
                        <p align="middle"></p>

                        <li>
                            <br />
                            <br />
                            {/*<Button
                                className={classesLoading.rootStartButton}
                                variant="outlined"
                                //fullWidth="true"
                                size="small"
                                color="primary"
                                // disabled={DisableButton}
                                onClick={() => {
                                    triggerContenChartsandGraphics();
                                    triggerAxios();
                                    setLoadAnalysis(true);
                                    setProgress(0);
                                    setBuffer(10);
                                    triggerLoadingProzess();
                                    triggerLoadingAct();
                                }}
                            >
                                <ul>
                                    <IconStart />
                                    Start Evaluation
                                </ul>
                            </Button>*/}
                            <span>
                                <IconStart align="middle" />
                                <a
                                    onClick={() => {


                                        const user = "xyz";
                                        fetch("api/counter_AHP_Start/" + String(user))

                                        
                                        if (DisableButton == false) {
                                            setFinalList([])
                                            finalListAsObject = [];
                                            setInspectMill("")
                                          //  //console.log("inspectMill2" + inspectMill)
                                           triggerContenChartsandGraphics();
                                            triggerAxios();
                                            setLoadAnalysis(true);
                                            setProgress(0);
                                            setBuffer(10);
                                            triggerLoadingProzess();
                                            triggerLoadingAct();
                                        }  

                                        }
                                    }
                                >
                                    
                                    <span aria-hidden="true">{ButtonEvalText}</span>
                                </a>
                            </span>
                        </li>
                        <li>
                            <p align="middle"></p>
                        </li>
                    </ul>
                    <p style={{ color: "red" }}>{warntextMillSelection}</p>
                    {contentA}
                    {contentB}
                    <br />
                    <br />
                    {contentLoadingAct}
                    <td></td>
                    <td> {textFinishedLoading}</td>
                    <td></td>
                </section>
            </article>
            {contenChartsandGraphics}
        </div>
    );
}
