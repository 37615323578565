import React, { useState, useContext } from "react";
import InfoButtonApp from "../../../../Container/InfoButton/infoButton_App";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import theDefaultContext from "../defaultContext";

export default function TypeOfApplication() {
    const { setFinalValueTypeOfApp } = useContext(theDefaultContext);

    const [valueTypeOfApp, setValueTypeOfApp] = useState("SC_Fine"); // Default Value

    const handleChange = (event) => {
        setValueTypeOfApp(event.target.value);
    };
    setFinalValueTypeOfApp(valueTypeOfApp);

    let content;
    if (valueTypeOfApp === "SC_Fine") {
        content = (
            <div>
                <h4>Fine grinding: </h4>
                <p>
                    Typical application for cement grinding in a fineness-range
                    between 90 µm and 6 µm (D50).
                </p>
            </div>
        );
    } else if (valueTypeOfApp === "SC_Raw_material") {
        content = (
            <div>
                <h4>Raw material grinding: </h4>
                <p>
                    This option stands for common requirements for raw material
                    grinding. This includes a focus on narrow ‘Particle size
                    distributions’ (PSD).
                </p>
            </div>
        );
    } else if (valueTypeOfApp === "SC_Coarse") {
        content = (
            <div>
                <h4>Coarse grinding: </h4>
                <p>
                    Option to choose for a needed fineness-range between 5 mm
                    and 250 µm (D50). This setting might be of interest in case
                    of pre-grinding devices.
                </p>
            </div>
        );
    } else if (valueTypeOfApp === "SC_Crushing") {
        content = (
            <div>
                <h4>Crushing: </h4>
                <p>
                    This type of application is used for evaluating technologies
                    suitable for crushing, where high throughputs and only a low
                    amount of fines are of interest.
                </p>
            </div>
        );
    } else if (valueTypeOfApp === "SC_Ultrafine") {
        content = (
            <div>
                <h4>Ultra-fine grinding: </h4>
                <p>
                    The option for ultra-fine grinding is implemented to
                    evaluate technologies, which provide a fineness-range
                    between 8 µm and 2 µm (D50).
                </p>
            </div>
        );
    }

    return (
        <article id="ApplicationType">
            <section>
                <h2>Type of Application</h2>
                <br />
                {/* <InfoButtonApp /> */}
                {/* <p style={{ color: "red" }}>
                    BETA<em> ~Work in progress </em>
                </p> */}
                <FormControl component="fieldset">
                    <FormLabel component="legend">
                        Choose your comminution task:
                    </FormLabel>
                    <RadioGroup
                        aria-label="Type of Application"
                        name="Type of Application"
                        value={valueTypeOfApp}
                        onChange={handleChange}
                        onClick={() => {
                            setValueTypeOfApp(valueTypeOfApp);
                        }}
                    >
                        {/*<FormControlLabel
                            value="SC_Crushing"
                            control={<Radio />}
                            label="Crushing"
                        />*/}
                        <FormControlLabel
                            value="SC_Coarse"
                            control={<Radio />}
                            label="Coarse grinding"
                        />
                        <FormControlLabel
                            value="SC_Fine"
                            control={<Radio />}
                            label="Fine grinding"
                        />
                        <FormControlLabel
                            value="SC_Ultrafine"
                            control={<Radio />}
                            label="Ultra-fine grinding"
                        />
                        <FormControlLabel
                            value="SC_Raw_material"
                            control={<Radio />}
                            label="Raw material grinding"
                        />
                    </RadioGroup>
                </FormControl>
                {content}
            </section>
        </article>
    );
}
