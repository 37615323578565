import React, { useEffect, useState, useContext } from "react";
import InfoButtonMil from "../../../../Container/InfoButton/infoButton_Mil";
import IconAllMillsSelected from "../../../../Container/Icons/iconSelectMill";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import theDefaultContext from "../defaultContext";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button } from "@material-ui/core";
//import { event } from "jquery";
import { makeStyles } from "@material-ui/core/styles";
//import { useParams } from "react-router";
import Checkbox from '@material-ui/core/Checkbox';
import InfoButtonFilter from "../../../../Container/InfoButton/infoButton_Filter";
import {
} from "@material-ui/core";

//Original Template, Checkbox: https://material-ui.com/components/autocomplete/#checkboxes

//How to get value from selected object in list: https://stackoverflow.com/questions/58666189/getting-the-value-in-the-react-material-ui-autocomplete

//Lösungsansatz: https://www.codegrepper.com/code-examples/typescript/how+to+get+value+from+autocomplete+material+ui
//                 https://hub.packtpub.com/implementing-autocompletion-in-a-react-material-ui-application-tutorial/

export default function ArticelMillSelect() {
    const { selectMillReceivedValue, setSelectMillReceivedValue } =
        useContext(theDefaultContext);
    const { millSelectRadioStatus, setMillSelectRadioStatus } =
        useContext(theDefaultContext);
    const { millSelectionUserChoice, setMillSelectionUserChoice } =
        useContext(theDefaultContext); // gibt FinalScore der ausgewählten Mühlen wieder
    const { millSelectionCountMill, setMillSelectionCountMill } =
        useContext(theDefaultContext); // Gibt Namen der ausgewählten Mühlen wieder
    const { finalValueTypeOfApp } = useContext(theDefaultContext);
    const { allMillsWithoutFilters, setAllMillsWithoutFilters } =
        useContext(theDefaultContext);

    const [value, setValue] = useState("all");
    const [checked, setChecked] = useState(false);

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const handleCheckbox = (event) => {
        setChecked(event.target.checked);
    };
    setAllMillsWithoutFilters(checked);

    const stylesButtonAddList = {
        //Button:  "Add to List"
        rootButton: {
            marginTop: 25,
            marginLeft: 5,
            padding: "0.9rem ",
            display: "inline-block",
            // backgroundColor: "#ecf01494",
        },
        rootCombobox: {
            padding: "0.5rem 0rem 1rem 0rem", // Abstand nach: Höhre BreiteRechts Tiefe BreiteLinks
            display: "inline-block",

            // backgroundColor: "#ecf01494",
        },
    };

    const useStylesButton = makeStyles(stylesButtonAddList);
    const classButton = useStylesButton();

    const [open, setOpen] = useState(false);
    const [keys, setKeys] = useState(0);

    const [options, setOptions] = useState([]);
    const [millList, setMillList] = useState([]);
    const [millListNoDublicates, setMillListNoDublicates] = useState([]);
    const [millListAll, setMillListAll] = useState([]);
    const [idList, setIdList] = useState([]);
    const [nameList, setNameList] = useState([]);
    const [countMill, setCountMill] = useState(options.name);
    const [getId, setGetID] = useState(options.id);
    const [idFromAllMills, setIdFromAllMills] = useState(options);
    const [nameFromAllMills, setnameFromAllMills] = useState(options);
    const [openListAll, setOpenListAll] = useState(options.name);
    //const [disableAddToDoButton, setDisableAddToDoButton] = useState(false);

    // const [statusFilterMillSelection, setStatusFilterMillSelection] =
    //     useState(true);
    let statusFilterMillSelection = true;

    const loading = open && options.length === 0;

    const millListAllStatic = [
        // diese Variable dient nur für statische Werte für die Combobox (siehe dataSummary) und zur Orientierung,
        //in welchem Object-Format die Mühlen als Liste korrekt übertragen werden.
        { id: 48, Millname: "Impact crusher (rotor)", keys: 0 },
        { id: 48, Millname: "Ball ring mill", keys: 2 },
        { id: 11, Millname: "Fan beater mill", keys: 3 },
        { id: 6, Millname: "EDS multishaft mill", keys: 4 },
        { id: 14, Millname: "Griffin mill", keys: 5 },
        { id: 45, Millname: "Beater mill", keys: 6 },
        { id: 17, Millname: "Hammer mill", keys: 7 },
        { id: 40, Millname: "Raymond mill", keys: 8 },
        { id: 1, Millname: "ARGO mill", keys: 10 },
        { id: 61, Millname: "Vibrating mill", keys: 11 },
        { id: 42, Millname: "Ring roller mill", keys: 12 },
        { id: 12, Millname: "Opposed jet mill", keys: 13 },
        { id: 34, Millname: "Planetary mill", keys: 14 },
        { id: 27, Millname: "Cyro grinding", keys: 15 },
        { id: 32, Millname: "Mortar mill", keys: 16 },
        { id: 44, Millname: "Slice mill", keys: 17 },
    ];
    const [millListAllStaticSTATE, setMillListAllStaticSTATE] =
        useState(millListAllStatic);
    setMillSelectRadioStatus(value);

    useEffect(() => {
        (async () => {
            const response = await fetch("api/millsSC");
            const millsAll = await response.json();

            setOptions(Object.keys(millsAll).map((id) => millsAll[id]));
            setOpenListAll(millsAll);
        })();
    }, [selectMillReceivedValue]);

    useEffect(() => {
        setMillListNoDublicates([
            ...millList
                .reduce((map, obj) => map.set(obj.Millname, obj), new Map())
                .values(),
        ]); //Dublikate verhindern. Damit keine Mühlennamen doppelt in der Liste auftauchen

        setMillSelectionUserChoice(ArrayWithNoDublicatesforID); /// only Array with ID //millListOnlyId
    }, [millList]);

    let AddTaskAllMills = () => {
        var countMillDetailsAll = {
            id: idFromAllMills, // ID
            Millname: nameFromAllMills, //Name aus SQL Liste
        };

        setMillListAll([...millListAll, countMillDetailsAll]);
        for (var i = 0; i >= 60; i++) {
            setIdFromAllMills(...idFromAllMills, openListAll[i].id);
            setnameFromAllMills(openListAll[i].name);
            //console.log(
            //    "setMillListAll wurde ausgelöst-> IdFromAllMills(article_MillSelect.js): ",
          //      idFromAllMills
           // );
        }
    };

    /////// Methoden für manuelle Mill-Liste: ///////////
    const AddTask = () => {
        if (countMill !== null) {
            var countMillDetails = {
                id: getId, // ID
                Millname: countMill, //Name aus SQL Liste
                delete: false, // für Symbol; Mühle aus Liste entfernen
                keys: keys,
            };
        }

        if (countMill !== undefined) {
            setKeys(keys + 1);

            setMillList([...millList, countMillDetails]);
            setIdList([...idList, countMillDetails.id]);
            setNameList([...nameList, countMillDetails.Millname]);
        }
    };

    const deleteTask = (e, id) => {
        e.preventDefault();
        setMillList(millList.filter((t) => t.id != id)); //gelöschte Mühlen müssen auch aus dem Array gelöscht werden
        setMillListNoDublicates(millListNoDublicates.filter((t) => t.id != id));
    };

    const ObjectToArray = millList.map((value) => {
        return value.id;
    });

    let ArrayWithNoDublicatesforID = [];

    ArrayWithNoDublicatesforID = [
        ...ObjectToArray.reduce(
            (map, obj) => map.set(obj, obj),
            new Map()
        ).values(),
    ];

    if (millSelectRadioStatus === "all") {
        setMillSelectionCountMill(millListAllStaticSTATE);
    } else if (millSelectRadioStatus === "individual") {
        setMillSelectionCountMill(millList);
    }

    useEffect(() => {
        AddTaskAllMills();
    }, [millSelectRadioStatus]);

    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            const response = await fetch("api/millsSC");
            const mills = await response.json();

            if (active) {
                setOptions(Object.keys(mills).map((id) => mills[id]));
                setGetID(Object.keys(mills).map((id) => mills[id]));
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    let text01;
    if (idList > [0]) {
        text01 = "List of chosen comminution technologies";
    } else {
        text01 = "";
    }

    let content;
    if (value === "all") {
        content = (
            <div>
                <p align="middle">
                    <IconAllMillsSelected /> Only suitable technologies are
                    selected.
                </p>
                <p align="left">
                    <InfoButtonFilter />
                </p>
            </div>
        );
    } else if (value === "individual") {
        content = (
            <div>

           

                <FormControlLabel
                    control={<Checkbox
                        checked={checked}
                        onChange={handleCheckbox}
                    />}
                    label="Add all technologies"
                /> 


                <td>
                    <Autocomplete
                        className={classButton.rootCombobox}
                        disableClearable
                        disabled={checked}
                        id="UI_Autocomplete"
                        style={{ width: 300 }}
                        open={open}
                        onOpen={() => {
                            setOpen(true);
                        }}
                        onClose={() => {
                            setOpen(false);
                        }}
                        getOptionSelected={(option, value) =>
                            option.name === value.name
                        }
                        getOptionLabel={(option) => option.name}
                        options={options}
                        loading={loading}
                        onChange={(event, value) => {
                            setCountMill(value.name);
                            setGetID(value.id);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Choose technologies"
                                variant="outlined"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? (
                                                <CircularProgress
                                                    color="inherit"
                                                    size={20}
                                                />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                </td>
                <td>
                    <Button
                        className={classButton.rootButton}
                        onClick={AddTask}
                        variant="outlined"
                        color="primary"
                        float="left"
                        disabled={checked}
                    >
                        Add to List
                    </Button>
                </td>
                <br />
                <h3>{text01}</h3>
                <br />
                {millListNoDublicates !== [] ? (
                    <ul>
                        {millListNoDublicates.map((t) => (
                            

                                <table width="100%"><tr><td>
                                {t.Millname}
                                    </td><td align = "right">

                                    <Button
                                        width="300"
                                    variant="outlined"
                                    color="secondary"
                                    className="autocomplete"
                                    onClick={(e) => deleteTask(e, t.id)}
                                >
                                        delete entry 
                                </Button>
                                    </td></tr></table>
                          
                        ))}
                    </ul>
                ) : null}
            </div>
        );
    }

    // //console.log("ObjectToArray: ", ObjectToArray);
    // //console.log("ArrayWithNoDublicatesforID: ", ArrayWithNoDublicatesforID);
    // //console.log("millSelectionUserChoice: ", millSelectionUserChoice);
    // //console.log("Status Filter: ", statusFilterMillSelection);
    // //console.log("millSelectRadioStatus: ", millSelectRadioStatus);

    return (
        <article id="MillSelection">
            <section className="MillSelection">
                <h2>Selection</h2>
                <InfoButtonMil />
                <FormControl component="fieldset">
                    <FormLabel component="legend">
                        {/* Choose how you want to analyse: */}
                    </FormLabel>
                    <RadioGroup
                        //row
                        aria-label="Select Mills"
                        name="Select Mills"
                        value={value}
                        onChange={handleChange}
                    >
                        <FormControlLabel
                            onValue={AddTaskAllMills}
                            value="all"
                            control={<Radio />}
                            label="Select all suitable technologies (Filters applied)"
                        />

                        <FormControlLabel
                            value="individual"
                            control={<Radio />}
                            label="Choose individually (Inactive filters)"
                        />
                    </RadioGroup>
                </FormControl>
                <br />
                {content}
            </section>
        </article>
    );
}
