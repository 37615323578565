import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import DvrIcon from '@material-ui/icons/Dvr';

// Icon Quelle: https://material-ui.com/components/material-icons/
// Seitenquelle: view-source:https://material-ui.com/components/material-icons/

const useStyles = makeStyles((theme) => ({
    root: {
        "& > svg": {
            margin: theme.spacing(1),
        },
    },
}));

function Icon2(props) {
    return (
        <DvrIcon {...props}>
            <path d="M17 19.22H5V7h7V5H5c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-7h-2v7.22z" />
            <path d="M19 2h-2v3h-3c.01.01 0 2 0 2h3v2.99c.01.01 2 0 2 0V7h3V5h-3V2zM7 9h8v2H7zM7 12v2h8v-2h-3zM7 15h8v2H7z" />
        </DvrIcon>
    );
}

function Icon3(props) {
    return (
        <DvrIcon {...props}>
            <path d="M17 19.22H5V7h7V5H5c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-7h-2v7.22z" />
            <path d="M19 2h-2v3h-3c.01.01 0 2 0 2h3v2.99c.01.01 2 0 2 0V7h3V5h-3V2zM7 9h8v2H7zM7 12v2h8v-2h-3zM7 15h8v2H7z" />
        </DvrIcon>
    );
}

export default function IconFormular() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Icon3 style={{ fontSize: 60 }} />
        </div>
    );
}
