import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import theDefaultContext from "../../defaultContext";

const inputStyle = {
    color: "blue",
    border: "none",
    size: "small",
    margin: "dense",
    outline: "none",
    width: 50,
};

//Quality
const marks01 = [
    { value: 0, label: "0 %" },
    { value: 25, label: "25 %" },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 35, label: <strong>Pre</strong> },
];
const marks02 = [
    { value: 0, label: "0 %" },
    { value: 25 },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 30, label: <strong>Pre</strong> },
];
const marks03 = [
    { value: 0, label: "0 %" },
    { value: 25 },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 20, label: <strong>Pre</strong> },
];
const marks04 = [
    { value: 0, label: "0 %" },
    { value: 25, label: "25 %" },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 15, label: <strong>Pre</strong> },
];

//Feed / Flexibility
const marks05 = [
    { value: 0, label: "0 %" },
    { value: 25, label: "25 %" },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 60, label: <strong>Pre</strong> },
];
const marks06 = [
    { value: 0, label: "0 %" },
    { value: 25, label: "25 %" },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 40, label: <strong>Pre</strong> },
];

//Operation
const marks07 = [
    { value: 0, label: "0" },
    { value: 25, label: "25 %" },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 5, label: <strong>Pre</strong> },
];
const marks08 = [
    { value: 0, label: "0 %" },
    { value: 25 },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 25, label: <strong>Pre</strong> },
];
const marks09 = [
    { value: 0, label: "0 %" },
    { value: 25 },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 20, label: <strong>Pre</strong> },
];
const marks10 = [
    { value: 0, label: "0 %" },
    { value: 25 },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 20, label: <strong>Pre</strong> },
];
const marks11 = [
    { value: 0, label: "0 %" },
    { value: 25 },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 30, label: <strong>Pre</strong> },
];

//Maintenance
const marks12 = [
    { value: 0, label: "0 %" },
    { value: 25, label: "25 %" },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 40, label: <strong>Pre</strong> },
];
const marks13 = [
    { value: 0, label: "0 %" },
    { value: 25 },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 20, label: <strong>Pre</strong> },
];
const marks14 = [
    { value: 0, label: "0 %" },
    { value: 25, label: "25 %" },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 40, label: <strong>Pre</strong> },
];

//Efficiency
const marks15 = [
    { value: 0, label: "0 %" },
    { value: 25, label: "25 %" },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100 },
    { value: 95, label: <strong>Pre</strong> },
];
const marks16 = [
    { value: 0 },
    { value: 25, label: "25 %" },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 5, label: <strong>Pre</strong> },
];

//Environmental Impact
const marks17 = [
    { value: 0, label: "0 %" },
    { value: 25 },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 30, label: <strong>Pre</strong> },
];
const marks18 = [
    { value: 0, label: "0 %" },
    { value: 25, label: "25 %" },
    { value: 50 },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 50, label: <strong>Pre</strong> },
];
const marks19 = [
    { value: 0, label: "0 %" },
    { value: 25 },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 20, label: <strong>Pre</strong> },
];

const useStyles = makeStyles({
    root: {
        width: 300,
    },
});

export default function SliderCritCustom() {
    ///////////////////////////////////////////////////////////////////////////  Slider Values für den Provider:
    const { setPsd } = useContext(theDefaultContext);
    const { setMaxFineness } = useContext(theDefaultContext);
    const { setDehydration } = useContext(theDefaultContext);
    const { setDrying } = useContext(theDefaultContext);
    const { setMaxFeedSize } = useContext(theDefaultContext);
    const { setProductChangingTime } = useContext(theDefaultContext);
    const { setUnderstandingOfProcess } = useContext(theDefaultContext);
    const { setSensitivityOfProcess } = useContext(theDefaultContext);
    const { setControlabilityPersonnel } = useContext(theDefaultContext);
    const { setAvailableExperience } = useContext(theDefaultContext);
    const { setThroughput } = useContext(theDefaultContext);
    const { setMaintenanceTime } = useContext(theDefaultContext);
    const { setComplexityOfMaintenance } = useContext(theDefaultContext);
    const { setWearCosts } = useContext(theDefaultContext);
    const { setEnergyDemand } = useContext(theDefaultContext);
    const { setWaterDemand } = useContext(theDefaultContext);
    const { setDustEmissions } = useContext(theDefaultContext);
    const { setNoise } = useContext(theDefaultContext);
    const { setEffectOfVibration } = useContext(theDefaultContext);

    ///////////////////////////////////////////////////////////////////////////  Slider Values für diese Funktion:
    const classes = useStyles();
    const [valuePSD, setValuePSD] = useState(35);
    const [valueMaxFineness, setValueMaxFineness] = useState(30);
    const [valueDehydration, setValueDehydration] = useState(20);
    const [valueDrying, setValueDrying] = useState(15);
    const [valueMaxFeedSize, setValueMaxFeedSize] = useState(60);
    const [valueProductChangingTime, setValueProductChangingTime] =
        useState(40);
    const [valueUnderstandingOfProcess, setValueUnderstandingOfProcess] =
        useState(5);
    const [valueSensitivityOfProcess, setValueSensitivityOfProcess] =
        useState(25);
    const [valueControlabilityPersonnel, setValueControlabilityPersonnel] =
        useState(20);
    const [valueAvailableExperience, setValueAvailableExperience] =
        useState(20);
    const [valueThroughput, setValueThroughput] = useState(30);
    const [valueMaintenanceTime, setValueMaintenanceTime] = useState(40);
    const [valueComplexityOfMaintenance, setValueComplexityOfMaintenance] =
        useState(20);
    const [valueWearCosts, setValueWearCosts] = useState(40);
    const [valueEnergyDemand, setValueEnergyDemand] = useState(95);
    const [valueWaterDemand, setValueWaterDemand] = useState(5);
    const [valueDustEmissions, setValueDustEmissions] = useState(30);
    const [valueNoise, setValueNoise] = useState(50);
    const [valueEffectOfVibration, setValueEffectOfVibration] = useState(20);

    //////////////////////////////////// SwitchValue:
    const { limitOfSliderCriteria, setLimitOfSliderCriteria } =
        useContext(theDefaultContext);

    //////////////////////////////////////////////////////////////////////////////////// HandleChange
    const handleChangePSD = (e, newValue) => {
        setValuePSD(newValue);
        setPsd(newValue / 100);
    };

    const handleChangeMaxFineness = (e, newValue) => {
        setValueMaxFineness(newValue);
        setMaxFineness(newValue / 100);
    };

    const handleChangeDehydration = (e, newValue) => {
        setValueDehydration(newValue);
        setDehydration(newValue / 100);
    };

    const handleChangeDrying = (e, newValue) => {
        setValueDrying(newValue);
        setDrying(newValue / 100);
    };

    const handleChangeMaxFeedSize = (e, newValue) => {
        setValueMaxFeedSize(newValue);
        setMaxFeedSize(newValue / 100);
    };

    const handleChangeProductChangingTime = (e, newValue) => {
        setValueProductChangingTime(newValue);
        setProductChangingTime(newValue / 100);
    };

    const handleChangeUnderstandingOfProcess = (e, newValue) => {
        setValueUnderstandingOfProcess(newValue);
        setUnderstandingOfProcess(newValue / 100);
    };

    const handleChangeSensitivityOfProcess = (e, newValue) => {
        setValueSensitivityOfProcess(newValue);
        setSensitivityOfProcess(newValue / 100);
    };

    const handleChangeControlabilityPersonnel = (e, newValue) => {
        setValueControlabilityPersonnel(newValue);
        setControlabilityPersonnel(newValue / 100);
    };

    const handleChangeAvailableExperience = (e, newValue) => {
        setValueAvailableExperience(newValue);
        setAvailableExperience(newValue / 100);
    };

    const handleChangeThroughput = (e, newValue) => {
        setValueThroughput(newValue);
        setThroughput(newValue / 100);
    };

    const handleChangeMaintenanceTime = (e, newValue) => {
        setValueMaintenanceTime(newValue);
        setMaintenanceTime(newValue / 100);
    };

    const handleChangeComplexityOfMaintenance = (e, newValue) => {
        setValueComplexityOfMaintenance(newValue);
        setComplexityOfMaintenance(newValue / 100);
    };

    const handleChangeWearCosts = (e, newValue) => {
        setValueWearCosts(newValue);
        setWearCosts(newValue / 100);
    };

    const handleChangeEnergyDemand = (e, newValue) => {
        setValueEnergyDemand(newValue);
        setEnergyDemand(newValue / 100);
    };

    const handleChangeWaterDemand = (e, newValue) => {
        setValueWaterDemand(newValue);
        setWaterDemand(newValue / 100);
    };

    const handleChangeDustEmissions = (e, newValue) => {
        setValueDustEmissions(newValue);
        setDustEmissions(newValue / 100);
    };

    const handleChangeNoise = (e, newValue) => {
        setValueNoise(newValue);
        setNoise(newValue / 100);
    };

    const handleChangeEffectOfVibration = (e, newValue) => {
        setValueEffectOfVibration(newValue);
        setEffectOfVibration(newValue / 100);
    };

    const SumSliderQuality =
        valuePSD + valueMaxFineness + valueDehydration + valueDrying;
    const SumSliderFeed = valueMaxFeedSize + valueProductChangingTime;
    const SumSliderOperation =
        valueUnderstandingOfProcess +
        valueSensitivityOfProcess +
        valueControlabilityPersonnel +
        valueAvailableExperience +
        valueThroughput;
    const SumSliderMaintenance =
        valueMaintenanceTime + valueComplexityOfMaintenance + valueWearCosts;
    const SumSliderEfficiency = valueEnergyDemand + valueWaterDemand;
    const SumSliderEnvironment =
        valueDustEmissions + valueNoise + valueEffectOfVibration;

    let statusWarningHigh = <p>Please adapt your importances!</p>;
    let statusWarningLow = <p>Please adapt your importances!</p>;

    let SumSliderColorQuality;
    let SumSliderColorFeed;
    let SumSliderColorOperation;
    let SumSliderColorMaintenance;
    let SumSliderColorEfficiency;
    let SumSliderColorEnvironment;

    let setStatusSliderColorQuality = true;
    let setStatusSliderColorFeed = true;
    let setStatusSliderColorOperation = true;
    let setStatusSliderColorMaintenance = true;
    let setStatusSliderColorEfficiency = true;
    let setStatusSliderColorEnvironment = true;

    if (SumSliderQuality > 100) {
        SumSliderColorQuality = (
            <div style={{ color: "red" }}>
                {" "}
                Sum of categories: {SumSliderQuality} %  (Categories must be normalised)
            </div>
        );
        setStatusSliderColorQuality = true;
    } else {
        SumSliderColorQuality = (
            <div style={{ color: "red" }}>
                Sum of categories: {SumSliderQuality} %  (Categories must be normalised)
            </div>
        );
        setStatusSliderColorQuality = true;
    }
    if (SumSliderQuality === 100) {
        SumSliderColorQuality = (
            <div style={{ color: "green" }}>
                {" "}
                Sum of categories: {SumSliderQuality} %
            </div>
        );
        setStatusSliderColorQuality = false;
    }

    if (SumSliderFeed > 100) {
        SumSliderColorFeed = (
            <div style={{ color: "red" }}>
                {" "}
                Sum of categories: {SumSliderFeed} % (Categories must be normalised)
            </div>
        );
        setStatusSliderColorFeed = true;
    } else {
        SumSliderColorFeed = (
            <div style={{ color: "red" }}>
                Sum of categories: {SumSliderFeed} % (Categories must be normalised)
            </div>
        );
        setStatusSliderColorFeed = true;
    }
    if (SumSliderFeed === 100) {
        SumSliderColorFeed = (
            <div style={{ color: "green" }}>
                {" "}
                Sum of categories: {SumSliderFeed} %
            </div>
        );
        setStatusSliderColorFeed = false;
    }

    if (SumSliderOperation > 100) {
        SumSliderColorOperation = (
            <div style={{ color: "red" }}>
                {" "}
                Sum of categories: {SumSliderOperation} % (Categories must be normalised)
            </div>
        );
        setStatusSliderColorOperation = true;
    } else {
        SumSliderColorOperation = (
            <div style={{ color: "red" }}>
                Sum of categories: {SumSliderOperation} % (Categories must be normalised)
            </div>
        );
        setStatusSliderColorOperation = true;
    }
    if (SumSliderOperation === 100) {
        SumSliderColorOperation = (
            <div style={{ color: "green" }}>
                {" "}
                Sum of categories: {SumSliderOperation} %{" "}
            </div>
        );
        setStatusSliderColorOperation = false;
    }

    if (SumSliderMaintenance > 100) {
        SumSliderColorMaintenance = (
            <div style={{ color: "red" }}>
                {" "}
                Sum of categories: {SumSliderMaintenance} % (Categories must be normalised)
            </div>
        );
        setStatusSliderColorMaintenance = true;
    } else {
        SumSliderColorMaintenance = (
            <div style={{ color: "red" }}>
                Sum of categories: {SumSliderMaintenance} % (Categories must be normalised)
            </div>
        );
        setStatusSliderColorMaintenance = true;
    }
    if (SumSliderMaintenance === 100) {
        SumSliderColorMaintenance = (
            <div style={{ color: "green" }}>
                {" "}
                Sum of categories: {SumSliderMaintenance} %{" "}
            </div>
        );
        setStatusSliderColorMaintenance = false;
    }

    if (SumSliderEfficiency > 100) {
        SumSliderColorEfficiency = (
            <div style={{ color: "red" }}>
                {" "}
                Sum of categories: {SumSliderEfficiency} % (Categories must be normalised)
            </div>
        );
        setStatusSliderColorEfficiency = true;
    } else {
        SumSliderColorEfficiency = (
            <div style={{ color: "red" }}>
                Sum of categories: {SumSliderEfficiency} % (Categories must be normalised)
            </div>
        );
        setStatusSliderColorEfficiency = true;
    }
    if (SumSliderEfficiency === 100) {
        SumSliderColorEfficiency = (
            <div style={{ color: "green" }}>
                {" "}
                Sum of categories: {SumSliderEfficiency} %{" "}
            </div>
        );
        setStatusSliderColorEfficiency = false;
    }

    if (SumSliderEnvironment > 100) {
        SumSliderColorEnvironment = (
            <div style={{ color: "red" }}>
                {" "}
                Sum of categories: {SumSliderEnvironment} % (Categories must be normalised)
            </div>
        );
        setStatusSliderColorEnvironment = true;
    } else {
        SumSliderColorEnvironment = (
            <div style={{ color: "red" }}>
                Sum of categories: {SumSliderEnvironment} % (Categories must be normalised)
            </div>
        );
        setStatusSliderColorEnvironment = true;
    }
    if (SumSliderEnvironment === 100) {
        SumSliderColorEnvironment = (
            <div style={{ color: "green" }}>
                {" "}
                Sum of categories: {SumSliderEnvironment} %{" "}
            </div>
        );
        setStatusSliderColorEnvironment = false;
    }

    if (
        setStatusSliderColorQuality ||
        setStatusSliderColorFeed ||
        setStatusSliderColorOperation ||
        setStatusSliderColorMaintenance ||
        setStatusSliderColorEfficiency ||
        setStatusSliderColorEnvironment === true
    ) {
        setLimitOfSliderCriteria(true);
    } else {
        setLimitOfSliderCriteria(false);
    }

    return (
        <div>
            {/* <p style={{ color: "red" }}>Warning: </p>
            <p>Do not apply more or less than 100 %</p> */}
            <p style={{ color: "red" }}>Please note:</p>
            <p>
                Values can be typed in by selecting the blue numbers next to
                your category.
            </p>
            <br />
            
            <h3>Quality</h3>
            <Typography id="PSD" gutterBottom>
                Slope of Particle Size Distribution:
                <input
                    style={inputStyle}
                    type={valuePSD}
                    value={valuePSD}
                    onChange={(newValue) => {
                        setValuePSD(parseInt(newValue.target.value));
                    }}
                />
            </Typography>
            <Slider
                defaultValue={valuePSD}
                aria-labelledby="PSD"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={marks01}
                disabled={0}
                onChange={handleChangePSD}
                valueLabelFormat={valuePSD}
                value={valuePSD}
            />

            <Typography id="MaxFineness" gutterBottom>
                Maximum Fineness:{" "}
                <input
                    style={inputStyle}
                    type={valueMaxFineness}
                    value={valueMaxFineness}
                    onChange={(newValue) => {
                        setValueMaxFineness(parseInt(newValue.target.value));
                    }}
                />
            </Typography>
            <Slider
                defaultValue={valueMaxFineness}
                aria-labelledby="Max. Fineness"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={marks02}
                disabled={0}
                onChange={handleChangeMaxFineness}
                valueLabelFormat={valueMaxFineness}
                value={valueMaxFineness}
            />

            <Typography id="Dehydration" gutterBottom>
                Dehydration:{" "}
                <input
                    style={inputStyle}
                    type={valueDehydration}
                    value={valueDehydration}
                    onChange={(newValue) => {
                        setValueDehydration(parseInt(newValue.target.value));
                    }}
                />
            </Typography>
            <Slider
                defaultValue={valueDehydration}
                aria-labelledby="Dehydration"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={marks03}
                disabled={0}
                onChange={handleChangeDehydration}
                valueLabelFormat={valueDehydration}
                value={valueDehydration}
            />
            <Typography id="Drying" gutterBottom>
                Drying:{" "}
                <input
                    style={inputStyle}
                    type={valueDrying}
                    value={valueDrying}
                    onChange={(newValue) => {
                        setValueDrying(parseInt(newValue.target.value));
                    }}
                />
            </Typography>
            <Slider
                defaultValue={valueDrying}
                aria-labelledby="Drying"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={marks04}
                disabled={0}
                onChange={handleChangeDrying}
                valueLabelFormat={valueDrying}
                value={valueDrying}
            />

            {SumSliderColorQuality}
            <br />
            <br />

            <h3>Feed / Flexibility</h3>
            <Typography id="MaxFeedSize" gutterBottom>
                Max. Feed Size:{" "}
                <input
                    style={inputStyle}
                    type={valueMaxFeedSize}
                    value={valueMaxFeedSize}
                    onChange={(newValue) => {
                        setValueMaxFeedSize(parseInt(newValue.target.value));
                    }}
                />
            </Typography>
            <Slider
                defaultValue={valueMaxFeedSize}
                aria-labelledby="Max. Feed Size"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={marks05}
                disabled={0}
                onChange={handleChangeMaxFeedSize}
                valueLabelFormat={valueMaxFeedSize}
                value={valueMaxFeedSize}
            />
            <Typography id="ProductChangingTime" gutterBottom>
                Product Changing Time:{" "}
                <input
                    style={inputStyle}
                    type={valueProductChangingTime}
                    value={valueProductChangingTime}
                    onChange={(newValue) => {
                        setValueProductChangingTime(
                            parseInt(newValue.target.value)
                        );
                    }}
                />
            </Typography>
            <Slider
                defaultValue={valueProductChangingTime}
                aria-labelledby="Product Changing Time"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={marks06}
                disabled={0}
                onChange={handleChangeProductChangingTime}
                valueLabelFormat={valueProductChangingTime}
                value={valueProductChangingTime}
            />
            {SumSliderColorFeed}

            <br />
            <br />
            <h3>Operation</h3>

            <Typography id="UnderstandingOfProcess" gutterBottom>
                Understanding of Process:{" "}
                <input
                    style={inputStyle}
                    type={valueUnderstandingOfProcess}
                    value={valueUnderstandingOfProcess}
                    onChange={(newValue) => {
                        setValueUnderstandingOfProcess(
                            parseInt(newValue.target.value)
                        );
                    }}
                />
            </Typography>
            <Slider
                defaultValue={valueUnderstandingOfProcess}
                aria-labelledby="Understanding of Process"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={marks07}
                disabled={0}
                onChange={handleChangeUnderstandingOfProcess}
                valueLabelFormat={valueUnderstandingOfProcess}
                value={valueUnderstandingOfProcess}
            />
            <Typography id="Sensitivity of Process" gutterBottom>
                Sensitivity of Process:{" "}
                <input
                    style={inputStyle}
                    type={valueSensitivityOfProcess}
                    value={valueSensitivityOfProcess}
                    onChange={(newValue) => {
                        setValueSensitivityOfProcess(
                            parseInt(newValue.target.value)
                        );
                    }}
                />
            </Typography>
            <Slider
                defaultValue={valueSensitivityOfProcess}
                aria-labelledby="Sensitivity of Process"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={marks08}
                disabled={0}
                onChange={handleChangeSensitivityOfProcess}
                valueLabelFormat={valueSensitivityOfProcess}
                value={valueSensitivityOfProcess}
            />
            <Typography id=" Controlability/Personnel" gutterBottom>
                Controlabillity/ Personnel:{" "}
                <input
                    style={inputStyle}
                    type={valueControlabilityPersonnel}
                    value={valueControlabilityPersonnel}
                    onChange={(newValue) => {
                        setValueControlabilityPersonnel(
                            parseInt(newValue.target.value)
                        );
                    }}
                />
            </Typography>
            <Slider
                defaultValue={valueControlabilityPersonnel}
                aria-labelledby="Controlability/Personnel"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={marks09}
                disabled={0}
                onChange={handleChangeControlabilityPersonnel}
                valueLabelFormat={valueControlabilityPersonnel}
                value={valueControlabilityPersonnel}
            />
            <Typography id="AvailableExperience" gutterBottom>
                Available Experience:{" "}
                <input
                    style={inputStyle}
                    type={valueAvailableExperience}
                    value={valueAvailableExperience}
                    onChange={(newValue) => {
                        setValueAvailableExperience(
                            parseInt(newValue.target.value)
                        );
                    }}
                />
            </Typography>
            <Slider
                defaultValue={valueAvailableExperience}
                aria-labelledby="Available Experience"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={marks10}
                disabled={0}
                onChange={handleChangeAvailableExperience}
                valueLabelFormat={valueAvailableExperience}
                value={valueAvailableExperience}
            />
            <Typography id="Throughput" gutterBottom>
                Throughput:{" "}
                <input
                    style={inputStyle}
                    type={valueAvailableExperience}
                    value={valueAvailableExperience}
                    onChange={(newValue) => {
                        setValueAvailableExperience(
                            parseInt(newValue.target.value)
                        );
                    }}
                />
            </Typography>
            <Slider
                defaultValue={valueThroughput}
                aria-labelledby="Throughput"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={marks11}
                disabled={0}
                onChange={handleChangeThroughput}
                valueLabelFormat={valueThroughput}
                value={valueThroughput}
            />
            {SumSliderColorOperation}

            <br />
            <br />
            <h3>Maintenance</h3>

            <Typography id="MaintenanceTime" gutterBottom>
                Maintenance Time:{" "}
                <input
                    style={inputStyle}
                    type={valueMaintenanceTime}
                    value={valueMaintenanceTime}
                    onChange={(newValue) => {
                        setValueMaintenanceTime(
                            parseInt(newValue.target.value)
                        );
                    }}
                />
            </Typography>
            <Slider
                defaultValue={valueMaintenanceTime}
                aria-labelledby="Maintenance Time"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={marks12}
                disabled={0}
                onChange={handleChangeMaintenanceTime}
                valueLabelFormat={valueMaintenanceTime}
                value={valueMaintenanceTime}
            />
            <Typography id="ComplexityOfMaintenance" gutterBottom>
                Complexity of Maintenance:{" "}
                <input
                    style={inputStyle}
                    type={valueComplexityOfMaintenance}
                    value={valueComplexityOfMaintenance}
                    onChange={(newValue) => {
                        setValueComplexityOfMaintenance(
                            parseInt(newValue.target.value)
                        );
                    }}
                />
            </Typography>
            <Slider
                defaultValue={valueComplexityOfMaintenance}
                aria-labelledby="Complexity of Maintenance"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={marks13}
                disabled={0}
                onChange={handleChangeComplexityOfMaintenance}
                valueLabelFormat={valueComplexityOfMaintenance}
                value={valueComplexityOfMaintenance}
            />
            <Typography id="WearCosts" gutterBottom>
                Wear Costs:{" "}
                <input
                    style={inputStyle}
                    type={valueWearCosts}
                    value={valueWearCosts}
                    onChange={(newValue) => {
                        setValueWearCosts(parseInt(newValue.target.value));
                    }}
                />
            </Typography>
            <Slider
                defaultValue={valueWearCosts}
                aria-labelledby="Wear Costs"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={marks14}
                disabled={0}
                onChange={handleChangeWearCosts}
                valueLabelFormat={valueWearCosts}
                value={valueWearCosts}
            />
            {SumSliderColorMaintenance}

            <br />
            <br />
            <h3>Efficiency</h3>

            <Typography id="EnergyDemand" gutterBottom>
                Energy Demand:{" "}
                <input
                    style={inputStyle}
                    type={valueEnergyDemand}
                    value={valueEnergyDemand}
                    onChange={(newValue) => {
                        setValueEnergyDemand(parseInt(newValue.target.value));
                    }}
                />
            </Typography>
            <Slider
                defaultValue={valueEnergyDemand}
                aria-labelledby="Energy Demand"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={marks15}
                disabled={0}
                onChange={handleChangeEnergyDemand}
                valueLabelFormat={valueEnergyDemand}
                value={valueEnergyDemand}
            />
            <Typography id="WaterDemand" gutterBottom>
                Water Demand:{" "}
                <input
                    style={inputStyle}
                    type={valueWaterDemand}
                    value={valueWaterDemand}
                    onChange={(newValue) => {
                        setValueWaterDemand(parseInt(newValue.target.value));
                    }}
                />
            </Typography>
            <Slider
                defaultValue={valueWaterDemand}
                aria-labelledby="Water Demand"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={marks16}
                disabled={0}
                onChange={handleChangeWaterDemand}
                valueLabelFormat={valueWaterDemand}
                value={valueWaterDemand}
            />
            {SumSliderColorEfficiency}

            <br />
            <br />
            <h3>Environmental Impact</h3>

            <Typography id="DustEmissions" gutterBottom>
                Dust Emissions:{" "}
                <input
                    style={inputStyle}
                    type={valueDustEmissions}
                    value={valueDustEmissions}
                    onChange={(newValue) => {
                        setValueDustEmissions(parseInt(newValue.target.value));
                    }}
                />
            </Typography>
            <Slider
                defaultValue={valueDustEmissions}
                aria-labelledby="Dust Emissions"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={marks17}
                disabled={0}
                onChange={handleChangeDustEmissions}
                valueLabelFormat={valueDustEmissions}
                value={valueDustEmissions}
            />
            <Typography id="Noise" gutterBottom>
                Noise:{" "}
                <input
                    style={inputStyle}
                    type={valueNoise}
                    value={valueNoise}
                    onChange={(newValue) => {
                        setValueNoise(parseInt(newValue.target.value));
                    }}
                />
            </Typography>
            <Slider
                defaultValue={valueNoise}
                aria-labelledby="Noise"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={marks18}
                disabled={0}
                onChange={handleChangeNoise}
                valueLabelFormat={valueNoise}
                value={valueNoise}
            />
            <Typography id="EffectofVibration" gutterBottom>
                Effect of Vibration:{" "}
                <input
                    style={inputStyle}
                    type={valueEffectOfVibration}
                    value={valueEffectOfVibration}
                    onChange={(newValue) => {
                        setValueEffectOfVibration(
                            parseInt(newValue.target.value)
                        );
                    }}
                />
            </Typography>
            <Slider
                defaultValue={valueEffectOfVibration}
                aria-labelledby="Effect of Vibration"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={marks19}
                disabled={0}
                onChange={handleChangeEffectOfVibration}
                valueLabelFormat={valueEffectOfVibration}
                value={valueEffectOfVibration}
            />
            {SumSliderColorEnvironment}
        </div>
    );
}
