import React, { useState, useContext } from "react";
import SliderCritDefault from "./SliderScenarioDetail/slider+_default";
import SliderCritCustom from "./SliderScenarioDetail/slider+_custom";
import { Checkbox } from "@material-ui/core";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import theDefaultContext from "../defaultContext";

export default function ArticelScenarioDetailsCustom() {
    const { criteriaRadioScenario, setCriteriaRadioScenario } =
        useContext(theDefaultContext);
    const [checked, setChecked] = useState(false);

    setCriteriaRadioScenario(checked);

    const handleCheckbox = (event) => {
        setChecked(event.target.checked);
    };

    let content;
    if (checked === true) {
        content = (
            <div>
                <SliderCritCustom />
            </div>
        );
    } else {
        content = <SliderCritDefault />;
    }

    return (
        <div>

            <FormControlLabel
                control={<Checkbox
                    checked={checked}
                    onChange={handleCheckbox}
                />}
                label="Customise (on your own responsibility)"
            /> 
            
            <p style={{ color: "#a52525e0" }}>
                Be aware that in case of an inexperienced user this option can
                have a high impact on the analysis. Only change the values if
                you are conscious of the effects.
                <br />
                In case do not hesitate to contact us.
            </p>
            <br />
            {content}
        </div>
    );
}
