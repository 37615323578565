import { Checkbox } from "@material-ui/core";
import React, { useState } from "react";
import InfoButtonScenarioDetails from "../../../../Container/InfoButton/infoButton_ScenarioDeatils";
import ArticelScenarioDetailsCustom from "./articel_ScenarioDetailsCustom";
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default function ArticelScenarioDetails() {
    const [checked, setChecked] = useState(false);

    const handleCheckbox = (event) => {
        setChecked(event.target.checked);
    };

    let content;
    if (checked === true) {
        content = (
            <div>
                <ArticelScenarioDetailsCustom />
            </div>
        );
    } else {
        content = null;
    }

    return (
        <div>
            {/* <article id="ScenarioDetails">
             <section> 
            <h2>Scenario Details</h2>*/}
            <InfoButtonScenarioDetails />
            {/*  <p>
                If you want to see an overview of the predefined weighting of
                all categories and criteria:
            </p>*/}
           
            <FormControlLabel
                control={<Checkbox
                    checked={checked}
                    onChange={handleCheckbox} 
                />}
                label="Show details (overview of the predefined weighting of
                all categories and criteria)    "
            />
            
            {content}
            {/* </section> 
        </article> */}
        </div>
    );
}
