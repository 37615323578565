import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
//import Button from "@material-ui/core/Button";

import theDefaultContext from "../../defaultContext";

const inputStyle = {
    color: "blue",
    border: "none",
    size: "small",
    margin: "dense",
    outline: "none",
    width: 50,
};

// Slider-API from: https://material-ui.com/api/slider/
var markQualtiy = [
    { value: 0, label: "0 %" },
    { value: 25 },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 28, label: <strong>Pre</strong> },
];

var markFeed = [
    { value: 0, label: "0 %" },
    { value: 25, label: "25 %" },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 15, label: <strong>Pre</strong> },
];

var markOperation = [
    { value: 0, label: "0 %" },
    { value: 25 },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 17, label: <strong>Pre</strong> },
];

var markMaintenance = [
    { value: 0, label: "0 %" },
    { value: 25, label: "25 %" },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 15, label: <strong>Pre</strong> },
];

var markEfficiency = [
    { value: 0, label: "0 %" },
    { value: 25 },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 20, label: <strong>Pre</strong> },
];

var markEnvironment = [
    { value: 0, label: "0" },
    { value: 25 },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 5, label: <strong>Pre</strong> },
];

const useStyles = makeStyles({
    root: {
        width: 300,
    },
});

export default function SliderCatCustom() {
    //////////////////////////////////////////////////////////////////////////// Provider Werte:
    const { setCategoryQualityCustom } = useContext(theDefaultContext);
    const { setCategoryFeedFlexCustom } = useContext(theDefaultContext);
    const { setCategoryOperationCustom } = useContext(theDefaultContext);
    const { setCategoryMaintenanceCustom } = useContext(theDefaultContext);
    const { setCategoryEfficiencyCustom } = useContext(theDefaultContext);
    const { setCategoryEnvironmentCustom } = useContext(theDefaultContext);

    ///////////////////////////////////////////////////////////////////////////   Slider Values für den Provider:
    const classes = useStyles();
    const [valueQuality, setValueQualtiy] = useState(28);
    const [valueFeed, setValueFeed] = useState(15);
    const [valueOperation, setValueOperation] = useState(17);
    const [valueMaintenance, setValueMaintenance] = useState(15);
    const [valueEfficiency, setValueEfficiency] = useState(20);
    const [valueEnvironment, setValueEnvironment] = useState(5);

    //////////////////////////////////// SwitchValue:
    const { limitOfSliderCategorie, setLimitOfSliderCategorie } =
        useContext(theDefaultContext);

    const handleChangeQuality = (e, newValue) => {
        setValueQualtiy(newValue);
        setCategoryQualityCustom(newValue / 100);
    };

    const handleChangeFeed = (e, newValue) => {
        setValueFeed(newValue);
        setCategoryFeedFlexCustom(newValue / 100);
    };

    const handleChangeOperation = (e, newValue) => {
        setValueOperation(newValue);
        setCategoryOperationCustom(newValue / 100);
    };

    const handleChangeMaintenance = (e, newValue) => {
        setValueMaintenance(newValue);
        setCategoryMaintenanceCustom(newValue / 100);
    };

    const handleChangeEfficiency = (e, newValue) => {
        setValueEfficiency(newValue);
        setCategoryEfficiencyCustom(newValue / 100);
    };

    const handleChangeEnvironment = (e, newValue) => {
        setValueEnvironment(newValue);
        setCategoryEnvironmentCustom(newValue / 100);
    };

    const SumSlider =
        valueQuality +
        valueFeed +
        valueOperation +
        valueMaintenance +
        valueEfficiency +
        valueEnvironment;

    let ColorSumSlider;
    if (SumSlider > 100) {
        ColorSumSlider = (
            <div style={{ color: "red" }}>
                {" "}
                Sum of Categories: {SumSlider} %{" "} (Categories must be normalised)
            </div>
        );
        setLimitOfSliderCategorie(true);
    } else {
        ColorSumSlider = (
            <div style={{ color: "red" }}>
                Sum of Categories: {SumSlider} % (Categories must be normalised)
            </div>
        );

        setLimitOfSliderCategorie(true);
    }
    if (SumSlider === 100) {
        ColorSumSlider = (
            <div style={{ color: "green" }}>
                {" "}
                Sum of categories: {SumSlider} %
            </div>
        );
        setLimitOfSliderCategorie(false);
    }

    return (
        <div>
            {/* <p style={{ color: "#e01111e0" }}>Warning: </p>
            <p>Do not apply more or less than 100 %</p> */}
            <p style={{ color: "#e01111e0" }}>Please note:</p>
            <p>
                Values can be typed in by selecting the blue numbers next to
                your category.
            </p>
            <br />
            <Typography id="Quality" gutterBottom>
                Quality:{" "}
                <input
                    style={inputStyle}
                    type={valueQuality}
                    value={valueQuality}
                    onChange={(newValue) => {
                        setValueQualtiy(parseInt(newValue.target.value));
                    }}
                />
            </Typography>
            <Slider
                defaultValue={valueQuality}
                aria-labelledby="Quality"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={markQualtiy}
                disabled={0}
                onChange={handleChangeQuality}
                valueLabelFormat={valueQuality}
                value={valueQuality}
            />

            <Typography id="Feed" gutterBottom>
                Feed / Flexibility:{" "}
                <input
                    style={inputStyle}
                    type={valueFeed}
                    value={valueFeed}
                    onChange={(newValue) => {
                        setValueFeed(parseInt(newValue.target.value));
                    }}
                />
            </Typography>
            <Slider
                defaultValue={valueFeed}
                aria-labelledby="Feed"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={markFeed}
                disabled={0}
                onChange={handleChangeFeed}
                valueLabelFormat={valueFeed}
                value={valueFeed}
            />

            <Typography id="Operation" gutterBottom>
                Operation:{" "}
                <input
                    style={inputStyle}
                    type={valueOperation}
                    value={valueOperation}
                    onChange={(newValue) => {
                        setValueOperation(parseInt(newValue.target.value));
                    }}
                />
            </Typography>
            <Slider
                defaultValue={valueOperation}
                aria-labelledby="Operation"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={markOperation}
                disabled={0}
                onChange={handleChangeOperation}
                valueLabelFormat={valueOperation}
                value={valueOperation}
            />
            <Typography id="Maintenance" gutterBottom>
                Maintenance:{" "}
                <input
                    style={inputStyle}
                    type={valueMaintenance}
                    value={valueMaintenance}
                    onChange={(newValue) => {
                        setValueMaintenance(parseInt(newValue.target.value));
                    }}
                />
            </Typography>
            <Slider
                defaultValue={valueMaintenance}
                aria-labelledby="Maintenance"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={markMaintenance}
                disabled={0}
                onChange={handleChangeMaintenance}
                valueLabelFormat={valueMaintenance}
                value={valueMaintenance}
            />
            <Typography id="Efficiency" gutterBottom>
                Efficiency:{" "}
                <input
                    style={inputStyle}
                    type={valueEfficiency}
                    value={valueEfficiency}
                    onChange={(newValue) => {
                        setValueEfficiency(parseInt(newValue.target.value));
                    }}
                />
            </Typography>
            <Slider
                defaultValue={valueEfficiency}
                aria-labelledby="discrete-slider-custom"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={markEfficiency}
                disabled={0}
                onChange={handleChangeEfficiency}
                valueLabelFormat={valueEfficiency}
                value={valueEfficiency}
            />
            <Typography id="EnvironmImp" gutterBottom>
                Environmental Impact:{" "}
                <input
                    style={inputStyle}
                    type={valueEnvironment}
                    value={valueEnvironment}
                    onChange={(newValue) => {
                        setValueEnvironment(parseInt(newValue.target.value));
                    }}
                />
            </Typography>
            <Slider
                defaultValue={valueEnvironment}
                aria-labelledby="Environm. Imp."
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={markEnvironment}
                disabled={0}
                onChange={handleChangeEnvironment}
                valueLabelFormat={valueEnvironment}
                value={valueEnvironment}
            />
            {ColorSumSlider}
        </div>
    );
}
