import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";

// Slider-API from: https://material-ui.com/api/slider/

var markQualtiy = [
    { value: 0, label: "0 %" },
    { value: 25 },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 28, label: <strong>Pre</strong> },
];

var markFeed = [
    { value: 0, label: "0 %" },
    { value: 25, label: "25 %" },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 15, label: <strong>Pre</strong> },
];

var markOperation = [
    { value: 0, label: "0 %" },
    { value: 25 },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 17, label: <strong>Pre</strong> },
];

var markMaintenance = [
    { value: 0, label: "0 %" },
    { value: 25, label: "25 %" },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 15, label: <strong>Pre</strong> },
];

var markEfficiency = [
    { value: 0, label: "0 %" },
    { value: 25 },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 20, label: <strong>Pre</strong> },
];

var markEnvironment = [
    { value: 0, label: "0" },
    { value: 25, label: "25 %" },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 5, label: <strong>Pre</strong> },
];

const useStyles = makeStyles({
    root: {
        width: 300,
    },
});

export default function SliderCatDefault() {
    const classes = useStyles();
    const [valueQuality, setValueQualtiy] = useState(28);
    const [valueFeed, setValueFeed] = useState(15);
    const [valueOperation, setValueOperation] = useState(17);
    const [valueMaintenance, setValueMaintenance] = useState(15);
    const [valueEfficiency, setValueEfficiency] = useState(20);
    const [valueEnvironment, setValueEnvironment] = useState(5);

    const handleChangeQuality = (e, newValue) => {
        setValueQualtiy(newValue);
    };

    const handleChangeFeed = (e, newValue) => {
        setValueFeed(newValue);
    };

    const handleChangeOperation = (e, newValue) => {
        setValueOperation(newValue);
    };

    const handleChangeMaintenance = (e, newValue) => {
        setValueMaintenance(newValue);
    };

    const handleChangeEfficiency = (e, newValue) => {
        setValueEfficiency(newValue);
    };

    const handleChangeEnvironment = (e, newValue) => {
        setValueEnvironment(newValue);
    };

    return (
        <div>
            <Typography id="Quality" gutterBottom>
                Quality: {valueQuality}
            </Typography>
            <Slider
                defaultValue={valueQuality}
                aria-labelledby="Quality"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={markQualtiy}
                disabled={true}
                onChange={handleChangeQuality}
            />

            <Typography id="Feed" gutterBottom>
                Feed / Flexibility: {valueFeed}
            </Typography>
            <Slider
                defaultValue={valueFeed}
                aria-labelledby="Feed"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={markFeed}
                disabled={true}
                onChange={handleChangeFeed}
            />

            <Typography id="Operation" gutterBottom>
                Operation: {valueOperation}
            </Typography>
            <Slider
                defaultValue={valueOperation}
                aria-labelledby="Operation"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={markOperation}
                disabled={true}
                onChange={handleChangeOperation}
            />
            <Typography id="Maintenance" gutterBottom>
                Maintenance: {valueMaintenance}
            </Typography>
            <Slider
                defaultValue={valueMaintenance}
                aria-labelledby="Maintenance"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={markMaintenance}
                disabled={true}
                onChange={handleChangeMaintenance}
            />
            <Typography id="Efficiency" gutterBottom>
                Efficiency: {valueEfficiency}
            </Typography>
            <Slider
                defaultValue={valueEfficiency}
                aria-labelledby="discrete-slider-custom"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={markEfficiency}
                disabled={true}
                onChange={handleChangeEfficiency}
            />
            <Typography id="EnvironmImp" gutterBottom>
                Environmental Impact: {valueEnvironment}
            </Typography>
            <Slider
                defaultValue={valueEnvironment}
                aria-labelledby="Environm. Imp."
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={markEnvironment}
                disabled={true}
                onChange={handleChangeEnvironment}
            />
        </div>
    );
}
