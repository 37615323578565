import React from "react";
import "../../SCSS/styleInfoButton.scss";

//Template: https://codepen.io/MrJonesOBA/pen/jEzwbJ

function InfoButtonApp() {
    return (
        <div class="help">
            <div class="question">?</div>

            <div class="popup">
                <h3>Help: Type of Application</h3>
                <br />
                <p>
                    <strong>here</strong> select the type of application that
                    best adapts to your machining material
                </p>
                <br />
                <div class="tell-me">
                    <p>hover over me</p>
                    <div class="popup2">
                        <br />
                        <br />
                        <p>
                            If you leave this area blank, all types of
                            technology will be suggested
                        </p>
                        <br />
                        <br />
                        <p>
                            <em>
                                Side descriptions for individual units will be
                                available soon.
                            </em>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InfoButtonApp;
