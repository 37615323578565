import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";

// Icon Quelle: https://material-ui.com/components/material-icons/
// Seitenquelle: view-source:https://material-ui.com/components/material-icons/

const useStyles = makeStyles((theme) => ({
    root: {
        "& > svg": {
            margin: theme.spacing(1),
        },
    },
}));

function Icon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M8 5v14l11-7z" />
        </SvgIcon>
    );
}

export default function IconStart() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Icon style={{ fontSize: 60 }} />
        </div>
    );
}
