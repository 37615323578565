import React, { useState } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Paper, Tab, Tabs } from "@material-ui/core";
import InfoButtonApp from "./infoButton_App";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

const useStyles = makeStyles((theme) => ({
    root1: {
        display: "flex",
        width: "40px",
        margin: "auto",
        flexWrap: "wrap",
        "& > *": {
            width: theme.spacing("auto"),
            height: theme.spacing("auto"),
        },
    },
    root2: {
        justifyContent: "center",
        display: "flex",
        flexWrap: "wrap",
        padding: "5rem 4rem 4rem 4rem",
        "& > *": {
            width: theme.spacing(120),
            height: theme.spacing("auto"),
        },
    },
    root3: {
        justifyContent: "center",
    },

    inner: {
        textAlign: "justify",
        justifyContent: "center",
        display: "flex",
        flexWrap: "wrap",
        width: "90%",
    },

    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

export default function InfoButtonGui() {
    const [value, setValue] = useState();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen(!open);
    };

    return (
        <div>
            <IconButton
                className={classes.root1}
                variant="outlined"
                color="#ccc"
                onClick={handleToggle}
            >
                <HelpOutlineIcon fontSize="large" />
            </IconButton>
            <Backdrop
                className={classes.backdrop}
                open={open}
                onClick={handleClose}
                transitionDuration={425}
            >
                {/* <CircularProgress color="inherit" /> */}
                <div className={classes.root2}>
                    <Paper elevation={24} square={false}>
                        <hr />
                        <h3>Guideline: </h3>
                        <hr />
                        <p>
                            <center>
                                <div className={classes.inner}>
                            Here you will find instructions and
                            explanations for each topic.
                            <br />
                            <br />
                            For more detailed infomation for each article click
                            on the little (?) above.
                            <br />
                                </div>
                            </center>
                        </p>
                        <hr />
                    </Paper>

                    {/* <Paper>
                        <br />

                        <Tabs
                            value={value}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={handleChange}
                        >
                            <Tab label="Active" />
                            <Tab label="Disabled" />
                            <Tab label="Active" />
                        </Tabs>
                    </Paper> */}
                </div>
            </Backdrop>
        </div>
    );
}
