import React, { useState, useContext } from "react";
import InfoButtonSce from "../../../../Container/InfoButton/infoButton_Sce";
import SliderCatDefault from "./SliderScenarios/slider_default";
import SliderCatEnergy from "./SliderScenarios/slider_energy";
import SliderCatOperationAndMaintenance from "./SliderScenarios/slider_operationAndMaintenance";
import SliderCatQuality from "./SliderScenarios/slider_quality";
import SliderCatQualityAndEfficiency from "./SliderScenarios/slider_qualityAndEfficiency";
import SliderCatCustom from "./SliderScenarios/slider_custom";
import "../../../../SCSS/styleText.scss";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import theDefaultContext from "../defaultContext";
import ArticelScenarioDetails from "./articel_ScenarioDetails";

// How to use Slider: https://www.youtube.com/watch?v=J0DyW5oEGKo&list=WL&index=28

export default function Scenarios() {
    //Provider alle Custom-Werte
    //const { categoryQualityCustom } = useContext(theDefaultContext);

    //   Provider alle PresetKategorien
    const { setCategoryQuality } = useContext(theDefaultContext);
    const { setCategoryFeedFlex } = useContext(theDefaultContext);
    const { setCategoryOperation } = useContext(theDefaultContext);
    const { setCategoryMaintenance } = useContext(theDefaultContext);
    const { setCategoryEfficiency } = useContext(theDefaultContext);
    const { setCategoryEnvironment } = useContext(theDefaultContext);

    const { categoryRadioScenario, setCategoryRadioScenario } =
        useContext(theDefaultContext);

    // intern Variable:
    const [valueScenario, setValueScenario] = useState(
        "European standard setting"
    );

    const handleChange = (event) => {
        setValueScenario(event.target.value);
    };
    setCategoryRadioScenario(valueScenario);

    let content; // Wandelt Werte nach Entscheidung des users um und zeigt diese in einem Showcase
    let content2; // Infotext zu Art des Scenarios
    if (valueScenario === "European standard setting") {
        content = <SliderCatDefault />;
        setCategoryQuality(0.28);
        setCategoryFeedFlex(0.15);
        setCategoryOperation(0.17);
        setCategoryMaintenance(0.15);
        setCategoryEfficiency(0.2);
        setCategoryEnvironment(0.05);
    } else if (valueScenario === "Energy scenario") {
        content = <SliderCatEnergy />;
        setCategoryQuality(0.15);
        setCategoryFeedFlex(0.1);
        setCategoryOperation(0.15);
        setCategoryMaintenance(0.15);
        setCategoryEfficiency(0.4);
        setCategoryEnvironment(0.05);
    } else if (valueScenario === "Quality scenario") {
        content = <SliderCatQuality />;
        setCategoryQuality(0.45);
        setCategoryFeedFlex(0.15);
        setCategoryOperation(0.1);
        setCategoryMaintenance(0.1);
        setCategoryEfficiency(0.15);
        setCategoryEnvironment(0.05);
    } else if (valueScenario === "Operation and maintenance scenario") {
        content = <SliderCatOperationAndMaintenance />;
        setCategoryQuality(0.15);
        setCategoryFeedFlex(0.1);
        setCategoryOperation(0.3);
        setCategoryMaintenance(0.25);
        setCategoryEfficiency(0.15);
        setCategoryEnvironment(0.05);
    } else if (valueScenario === "Quality and efficiency scenario") {
        content = <SliderCatQualityAndEfficiency />;
        setCategoryQuality(0.5);
        setCategoryFeedFlex(0.0);
        setCategoryOperation(0.0);
        setCategoryMaintenance(0);
        setCategoryEfficiency(0.5);
        setCategoryEnvironment(0.0);
    } else if (valueScenario === "Custom") {
        content = <SliderCatCustom />;
    } else {
    }

    if (valueScenario === "European standard setting") {
        content2 = (
            <div>
                <h4>European standard setting: </h4>
                <p className="p_blocktext">
                    The ‘European standard setting’ has been proposed by the
                    ECRA grinding experts. By selection of this setting type,
                    the importance of all evaluation categories and criteria is
                    predefined by the ECRA expert team to be in a good agreement
                    with European market requirements (2020). Please note, that
                    the selection of this setting predefines the importance of
                    all categories and criteria.
                </p>
            </div>
        );
    } else if (valueScenario === "Energy scenario") {
        content2 = (
            <div>
                <h4>Energy scenario: </h4>
                <p className="p_blocktext">
                    By using the ‚energy scenario‘, a focus is set on the
                    category ‚efficiency‘ with an importance of 40 %. The
                    importance of all remaining categories are in the range of 5
                    to 15 %. This setting is used to identify technologies with
                    a low specific energy and water demand for cement grinding.
                    Please note, that the selection of this setting predefines
                    the importance of all categories and criteria.{" "}
                </p>
            </div>
        );
    } else if (valueScenario === "Quality scenario") {
        content2 = (
            <div>
                <h4>Quality scenario: </h4>
                <p className="p_blocktext">
                    To set the evaluation prioritisation on the category
                    ‘quality’, the ‘quality scenario’ is predestined. The
                    importance of ‘quality’ is set to 45 %, whereas all others
                    vary between 5 to 15 %. This setting is used to identify
                    technologies which provide an excellent product quality.
                    Please note, that the selection of this setting predefines
                    the importance of all categories and criteria.{" "}
                </p>
            </div>
        );
    } else if (valueScenario === "Operation and maintenance scenario") {
        content2 = (
            <div>
                <h4>Operation and maintenance scenario: </h4>
                <p className="p_blocktext">
                    The ‘operation and maintenance scenario’ mainly focusses on
                    the category ‘operation’ (30 % importance) and ‘maintenance’
                    (25 % importance). With this option, it is possible to
                    identify comminution devices with low maintenance effort,
                    combined with good operation conditions. Please note, that
                    the selection of this setting predefines the importance of
                    all categories and criteria.{" "}
                </p>
            </div>
        );
    } else if (valueScenario === "Quality and efficiency scenario") {
        content2 = (
            <div>
                <h4>Quality and efficiency scenario: </h4>
                <p className="p_blocktext">
                    In this scenario, the importance of ‘quality’ and
                    ‘efficiency’ is set to 50 %, so that all other categories do
                    not have any impact on the evaluation procedure. This
                    scenario might be of interest, wherever the requirements of
                    comminution are restricted to a fast, efficient production
                    of high quality cement. Please note, that the selection of
                    this setting predefines the importance of all categories and
                    criteria.{" "}
                </p>
            </div>
        );
    } else if (valueScenario === "Custom") {
        content2 = (
            <div>
                <h4>Customised setting: </h4>
                <p className="p_blocktext">
                    Please select this option to be able to set the category and
                    criteria importance based on your own requirements and
                    experiences. <br />First, it is recommended to set the importance
                    – expressed as percentage portion – of all categories, which
                    in sum needs to add up to 100 %.<br />Second, the subdivided
                    criteria importance for each category needs to be defined
                    and is also expressed as percentage portion.<br />Analogous to
                    the category importance, the percentage portion for the
                    criteria needs to add up to 100 % within each category.
                    Please note, that ECRA does not assume responsibility of the
                    evaluation results based on individual user settings. If you
                    consider publishing any results created by using this
                    evaluation procedure, please follow the suggested citation
                    modality.
                </p>
                <p style={{ color: "red" }} className="p_blocktext">
                    <em> How to cite: </em>Evaluation results have been created
                    by individual user settings and do not necessarily comply
                    with evaluation standards defined by ECRA’s grinding
                    experts.
                </p>
            </div>
        );
    }

    return (
        <article id="Scenarios">
            <section>
                <h2>Scenarios</h2>
                <InfoButtonSce />
                <FormControl component="fieldset">
                    <FormLabel component="legend">Type of Scenario:</FormLabel>
                    <RadioGroup
                        aria-label="Scenarios"
                        name="Scenarios" 
                        value={valueScenario}
                        onChange={handleChange}
                        onClick={() => {
                            setCategoryQuality(valueScenario);
                        }}
                    >
                        <FormControlLabel
                            value="European standard setting"
                            control={<Radio />}
                            label="European standard setting"
                        />
                        <FormControlLabel
                            value="Energy scenario"
                            control={<Radio />}
                            label="Energy scenario"
                        />
                        <FormControlLabel
                            value="Quality scenario"
                            control={<Radio />}
                            label="Quality scenario"
                        />
                        <FormControlLabel
                            value="Operation and maintenance scenario"
                            control={<Radio />}
                            label="Operation and maintenance scenario"
                        />
                        <FormControlLabel
                            value="Quality and efficiency scenario"
                            control={<Radio />}
                            label="Quality and efficiency scenario"
                        />
                        <FormControlLabel
                            value="Custom"
                            control={<Radio color="primary" />}
                            label="Customised setting"
                        />
                    </RadioGroup>
                </FormControl>
                {content2}
                <hr />
                {content}
                <br />
                <ArticelScenarioDetails />
            </section>
        </article>
    );
}
