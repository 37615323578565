import React, { useState, useEffect } from "react";
//import axios from "axios";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"; 
//import StartScreen from "./00_StartScreen/startScreen";
//import PrivacyStatement from "./00_StartScreen/dataStatement";
//import LoginPre from "./00_StartScreen/Login/loginpre";
import Mainmenu from "./01_MainMenu/mainMenu";
//import AddMill from "./02_Add Mill/addMill";
//import Research from "./02_Research/research";
import ManuelSearch from "./02_Research/manuelSearch";
//import KeySearch from "./02_Research/keySearch";
//import GroupSearch from "./02_Research/groupSearch";
import AHPalpha from "./02_AHP/02_AHP_Beta/ahp_Alpha";
import TextField from '@material-ui/core/TextField';
import IconFormular from "../Container/Icons/iconFormular";
//import { withRouter } from "react-router";
import Button from '@material-ui/core/Button';
 
export default function Nav() {



const [username, setUsername] = useState("");
const [password, setPassword] = useState("");
const [user, setUser] = useState()


    useEffect(() => {
        const loggedInUser = localStorage.getItem("user"); 
        if (loggedInUser) { 
            setUser("true");
        }
    }, []);


const handleSubmit = async e => {
    e.preventDefault();
    const user = [username+';'+ password ];

      // todo use md5
    const response = await fetch("api/login/"+String(user))
        .then(response => {
            return response.json();
        })

    //console.log(response)

    if (response) {
        setUser("true")
        // store the user in localStorage
        localStorage.setItem('user', true)
    } else {
        setUser("")
        return (
            alert('Wrong username or password')
        );
    }

  // //console.log(response.data)
};



    //console.log(user)
// if there's a user show the message below
    if (user) {
        return (
            <Router>
                <Switch>
                <Route path="/" exact component={Mainmenu} /> 
                <Route path="/technologydatabase" exact component={ManuelSearch} />
                    <Route path="/evaluation" exact component={AHPalpha} />
                    <Route component={Mainmenu} />
                    </Switch>
            </Router>
        ); 
    } else {
        {
            // if there's no user, show the login form
            return (
                 

                 <div>
            <br />
            <br />
            <br /> 
                    <br />
                            <br />
                             

                            <main id="styles">
                                <div class="container">
                                    <br />
                                    <br />
                                    <ul class="box">
                                        <li>
                                            <span>

                                        <form onSubmit={handleSubmit}>

                                            <TextField
                                                type="text"
                                                value={username}
                                                placeholder="Username"
                                                onChange={({ target }) => setUsername(target.value)} />
                                            <br />
                                            <TextField
                                                type="password"
                                                value={password}
                                                placeholder="Password"
                                                onChange={({ target }) => setPassword(target.value)}
                                            />

                                            <br />
                                            <br />

                                            <Button variant="outlined" type="submit">Login</Button>

                                        </form>
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                <IconFormular />
                                                <a href="mailto:fgt-support@ecra-online.org?subject=Ecra-FGT">
                                                    Contact ECRA team<span aria-hidden="true"></span>
                                                </a>
                                                <span> </span>
                                            </span>
                                        </li>
                            </ul>
                            
                            To improve your browsing experience ECRA uses cookies. By using the login to this website you agree to store the login-information in cookies.

                                </div>
                            </main>
                                      

                  

                    <br />
                    <br />
                    <br />
                    <br />
                </div>


            );

        }
    }
}
