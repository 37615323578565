import React, { useEffect, useState } from "react";


import Autocomplete from "@material-ui/lab/Autocomplete";
//import { Button } from "@material-ui/core";
import { Scatter } from "react-chartjs-2";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import IconFormular from "../../Container/Icons/iconFormular";
 

import Slider from "@material-ui/core/Slider";


 



function ManuelSearch() {


    //const [isDBalive, setIsDBalive] = useState(false);
    //(async () => {


    //    const isDBalive = await fetch("api/isDBalive")
    //        .then(response => {
    //            return response.json();
    //        })


    //    setIsDBalive(isDBalive);
    //})();


    //console.log("isDBalive" + isDBalive)

    // var data_Fineness = "";
    //// 'Spec. surface acc. to Blaine in cm²/g' 
    //var data_Surface = "";
    //// 'Width of PSD'
    //var data_PSD = "";
    //// 'Throughput'

    //var data_Throughput = "";
    // var data_finenessDescription = "";
    //var data_Energy = "";

    const [data_Fineness, setData_Fineness] = useState("");
    const [data_Surface, setData_Surface] = useState("");
    const [data_PSD, setData_PSD] = useState("");
    const [data_Throughput, setData_Throughput] = useState("");
    const [data_Energy, setData_Energy] = useState("");
    const [data_finenessDescription, setData_finenessDescription] = useState("");


    const [value, setValue] = useState("all"); // kann benutzt werden um zwischen Benutztereingabe und Hyperlink zu unterscheiden
    const [checked, setChecked] = useState(false);




    const handleChange = (event) => {
        setValue(event.target.value);
    };
    const handle
        = (event) => {
            setChecked(event.target.checked);
        };
    const stylesButtonAddList = {
        rootCombobox: {
            padding: "0.5rem 0rem 1rem 0rem", // Abstand nach: Höhre BreiteRechts Tiefe BreiteLinks
            display: "inline-block",
            // backgroundColor: "#ecf01494",
        },
    };

    const useStylesButton = makeStyles(stylesButtonAddList);
    const classButton = useStylesButton();
    const [open, setOpen] = useState(false);
    const [pointerIsURL, setPointerIsURL] = useState(true);
    const [options, setOptions] = useState([]);
    const [millInfo, setMillInfo] = useState([]);

    //  const [millID, setMillID] = useState([]);

    // Autocomplete Combobox Axes Line Graph
    const [valueXAxes, setValueXAxes] = useState([]);
    const [inputValueXAxes, setInputValueXAxes] = useState([]);
    //  const [dateOfUpdate, setDateOfUpdate] = useState([]);
    var dateOfUpdate = "";

    const [valueYAxes, setValueYAxes] = useState([]);
    //   const [trl, setTrl] = useState([]);
    const [inputValueYAxes, setInputValueYAxes] = useState([]);

    const [countMill, setCountMill] = useState([]); // Ausgewählte Mühle aus Combobox
    const [getId, setGetID] = useState(options.id);

    //var optionsXAxes = ['Spec. energy demand', 'Fineness', 'Spec. surface acc. to Blaine in cm²/g', 'Width of PSD', 'Throughput'];

    const [optionsXAxes, setOptionsXAxes] = useState(['Spec. energy demand', 'Fineness', 'Spec. surface acc. to Blaine in cm²/g', 'Width of PSD', 'Throughput']);


    var mill_name = countMill;

    let finalChoosenTechnologie;
    const loading = open && options.length === 0;

    // TODO get Millname from Url
    {/*}
    let getURLCheck = window.location.search.substring(1);
    if (getURLCheck.includes("name") && pointerIsURL) {
        //console.log("URL liefert einen Namen");
        GetURLParameter();
    } else {
        //console.log("URL liefert KEINEN Namen");
        finalChoosenTechnologie = mill_name;
    }
    */}




    const useStyles = makeStyles(theme => ({
        margin: {
            margin: theme.spacing(10),
            "& .MuiSlider-thumb": {
                height: 40,
                width: 40
            }
        }
    }));

    const CustomSlider = withStyles(theme => ({
        disabled: {
            color: theme.palette.primary.main
        },
        thumb: {
            // color: "red"
        }
    }))(Slider);




    function GetURLParameter(sParam) {
        var sPageURL = window.location.search.substring(1);
        var sURLVariables = sPageURL.split("&");
        for (var i = 0; i < sURLVariables.length; i++) {
            var sParameterName = sURLVariables[i].split("=");
            if (sParameterName[0] == sParam) {
                return sParameterName[1].replace(/%20/g, " ");
            }
        }
        return null
    }
    function precise(x) {
        return Number.parseFloat(x).toPrecision(2);
    }


    async function getDataFromDatabase(id) {
        //  let active = true;


        //console.log("try to load mill id: " + id)
        const mills = await fetch("api/mills/" + String(id))
            .then(response => {
                return response.json();
            })

        setMillInfo(mills);


        if (mills["name"] != "undefined") {
            var tdata_Fineness = String(mills["grindingTrials_fineness"]);
            var data_Fineness = tdata_Fineness.split(';');
            setData_Fineness(data_Fineness)
            var tdata_Surface = String(mills["grindingTrials_fineness_blaine"]);
            var data_Surface = tdata_Surface.split(';');
            setData_Surface(data_Surface)

            var tdata_PSD = String(mills["grindingTrials_fineness_n"]);
            var data_PSD = tdata_PSD.split(';');
            setData_PSD(data_PSD)

            var data_Throughput = String(mills["grindingTrials_throughput"]).split(';');
            //var data_Throughput = tdata_Throughput.split(';');
            setData_finenessDescription(String(mills["grindingTrials_fineness_description"]).split(';')[0]);

            setData_Throughput(data_Throughput)

            var data_Energy = String(mills["grindingTrials_energySystem"]).split(';');
            setData_Energy(data_Energy)
            //var data_Energy = tdata_Energy.split(';');


            mill_name = millInfo["name"];


            var o = [];

            if (data_Surface != "undefined" && data_Surface.length > 0 && !data_Surface.includes("nan")) {
                o.push('Spec. surface acc. to Blaine in cm²/g');
            }

            if (data_Fineness != "undefined" && data_Fineness.length > 0 && !data_Fineness.includes("nan")) {
                o.push('Fineness');
            }

            if (data_PSD != "undefined" && data_PSD.length > 0 && !data_PSD.includes("nan")) {
                o.push('Width of PSD');
            }

            if (data_Throughput != "undefined" && data_Throughput.length > 0 && !data_Throughput.includes("nan")) {
                o.push('Throughput');
            }

            if (data_Energy != "undefined" && data_Energy.length > 0 && !data_Energy.includes("nan")) {
                o.push('Spec. energy demand');
            }


            setOptionsXAxes(o);

            setValueYAxes(o[0])
            setValueXAxes(o[1])





        }

        const user = localStorage.getItem("user");
        fetch("api/counter_DB/" + String(user))
    }



    async function getDataFromDatabasebyName(name) {
        //  let active = true;

        const mills = await fetch("api/mill/" + String(name))
            .then(response => {
                return response.json();
            })

        setMillInfo(mills);



    }




    if (millInfo == null || millInfo.length == 0) {

        var nameFromURL = GetURLParameter('name');
        if (nameFromURL != null && nameFromURL != "") {
            (async () => {
                await getDataFromDatabasebyName(nameFromURL);
            })();
        } else {

            (async () => {
                await getDataFromDatabase(1);
            })();

        }


    }





    var mill_name = millInfo["name"];

    var description = millInfo["description"];
    var operation_mode = millInfo["operationMode"];
    var circuit = millInfo["circuit"];
    var material_hardness = millInfo["materialHardness"];
    var mill_category = millInfo["category"];
    var industry_sector = millInfo["industrySector"];

    var exists_picture = millInfo["exists_picture"];
    var exists_picture_function = millInfo["exists_picture_function"];
    var exists_report = millInfo["exists_report"];

    var mill_id = millInfo["id"];
    var video_link = millInfo["videoLink"];


    var source_text = String(millInfo["source"]);
    var source;
    if (source_text != "") {
        const source_text_splited = source_text.split("#");
        source = source_text_splited.map((number) => <li>{number}</li>);
    }




    var source_picture_text = String(millInfo["sourcePicture"]);
    var source_picture;
    if (source_picture_text != "") {
        const source_picture_text_splited = source_picture_text.split("#");

        source_picture = source_picture_text_splited.map((number) => <li>{number}</li>);
    }



    var source_FunctionPicture_text = String(millInfo["sourceFunction"]);
    var source_FunctionPicture;
    if (source_FunctionPicture_text != "") {
        const source_FunctionPicture_text_splited = source_FunctionPicture_text.split("#");
        source_FunctionPicture = source_FunctionPicture_text_splited.map((number) => <li>{number}</li>);
    }



    var manufacturers_text = String(millInfo["manufaturers"]);
    var manufacturers;
    if (manufacturers_text != "") {
        const manufacturers_text_splited = manufacturers_text.split("#");
        manufacturers = manufacturers_text_splited.map((number) => <li>{number}</li>);
    }


    var keywords_text = String(millInfo["keywords"]);
    var keywords = keywords_text;



    var accuracyIndex = 0;

    if (millInfo["accuracyIndex"] == "based on grinding trials") {
        accuracyIndex = 100;
    } else if (millInfo["accuracyIndex"] == "literature study") {

        accuracyIndex = 66;

    } else if (millInfo["accuracyIndex"] == "expert estimation") {

        accuracyIndex = 33;

    }


    // TRL
    var industrialScale = millInfo["trl"];
    const optionsDate = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    var dateOfTrials = new Date(String(millInfo["grindingTrials_Date"]).split(';')[0]);
    //console.log("dateOfTrials" + String(millInfo["grindingTrials_Date"]).split(';')[0])
    //new    
    var trial_info_t = String(millInfo["grindingTrials_Conductor"]).replace(";", " ").replace(";", " ");
    var trial_info = trial_info_t + " " + dateOfTrials.toLocaleDateString("en-GB", optionsDate);

    var GrindingTrial_id = []; // TODO



    var GrindingTrial_mill_name = millInfo["grindingTrials_MillName"];//['Argo', 'Argo', 'Argo', 'Argo', 'Argo', 'Argo'];
    if (GrindingTrial_mill_name != null && GrindingTrial_mill_name != "") {
        var GrindingTrial_mill_name_Array = GrindingTrial_mill_name.split(";")




        for (var i = 1; i <= GrindingTrial_mill_name_Array.length; i++) {
            if (GrindingTrial_mill_name_Array[i] != null && GrindingTrial_mill_name_Array != "") {
                GrindingTrial_id.push(i)
            }
        }
    }
    var GrindingTrial_Material = millInfo["grindingTrials_Material"];//['Limestone', 'Limestone', 'Limestone', 'Limestone', 'Limestone', 'Limestone']
    var GrindingTrial_feedSize = millInfo["grindingTrials_feedSize"];//[5, 5, 5, 5, 5, 5]
    var GrindingTrial_OperationMode = millInfo["grindingTrials_OperationMode"];//[5, 5, 5, 5, 5, 5]
    var GrindingTrial_DevelopmentState = millInfo["grindingTrials_DevelopmentState"];//['a', 'a', 'a', 'a', 'a', 'a']
    var GrindingTrials_GrindingAid = millInfo["grindingTrials_GrindingAid"];



    // 'Spec. Energy demand'
    // var data_Energy = [[1.3, 2.1, 3.4, 4, 5, 6], [1.3, 2.1, 3.4, 4, 5, 6], [1.3, 2.1, 3.4, 4, 5, 6], [1.3, 2.1, 3.4, 4, 5, 6], [1.3, 2.1, 3.4, 4, 5, 6], [1.3, 2.1, 3.4, 4, 5, 6]];
    //var tdata_Energy = String(millInfo["grindingTrials_energySystem"]);
    //var data_Energy = tdata_Energy.split(';');







    // data_Energy.split(';');
    // 'Fineness'
    //var data_Fineness = [[1.3, 2.1, 3.4, 4, 5, 6], [1.3, 2.1, 3.4, 4, 5, 6], [1.3, 2.1, 3.4, 4, 5, 6], [1.3, 2.1, 3.4, 4, 5, 6], [1.3, 2.1, 3.4, 4, 5, 6], [1.3, 2.1, 3.4, 4, 5, 6]];
    //var tdata_Fineness = String(millInfo["grindingTrials_fineness"]);
    //var data_Fineness = tdata_Fineness.split(';');
    //// 'Spec. surface acc. to Blaine in cm²/g'
    ////var data_Surface = [[12, 19, 3, 5, 2, 3], [1, 1, 1, 1, 1], [2, 2, 2, 2, 2], [2, 2, 2, 2, 2], [2, 2, 2, 2, 2], [2, 2, 2, 2, 2]];
    //var tdata_Surface = String(millInfo["grindingTrials_fineness_blaine"]);
    //var data_Surface = tdata_Surface.split(';');
    //// 'Width of PSD'
    ////var data_PSD = [[12, 19, 3, 5, 2, 3], [1, 1, 1, 1, 1], [2, 2, 2, 2, 2], [2, 2, 2, 2, 2], [2, 2, 2, 2, 2], [2, 2, 2, 2, 2]];
    //var tdata_PSD = String(millInfo["grindingTrials_fineness_n"]);
    //var data_PSD = tdata_PSD.split(';');
    //// 'Throughput'
    ////  var data_Throughput = [[12, 19, 3, 5, 2, 3], [1, 1, 1, 1, 1], [2, 2, 2, 2, 2], [2, 2, 2, 2, 2], [2, 2, 2, 2, 2], [2, 2, 2, 2, 2]];
    //var tdata_Throughput = String(millInfo["grindingTrials_throughput"]);
    //var data_Throughput = tdata_Throughput.split(';');
    //var data_finenessDescription = String(millInfo["grindingTrials_fineness_description"]).split(';');



    // setDateOfUpdate(String(millInfo["updateDate"]));
    // TODO
    if (String(millInfo["updateDate"]) != "null") {
        dateOfUpdate = String(millInfo["updateDate"]);
    }
    else {
        dateOfUpdate = "07.07.2021";
    }


    let autoCompleteReturn = (
        <Autocomplete
            className={classButton.rootCombobox}
            disableClearable
            disabled={checked}
            id="UI_Autocomplete"
            style={{ width: 300 }}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.name}
            defaultValue={{ name: finalChoosenTechnologie }} // IMPORTANT: https://stackoverflow.com/questions/61213634/assigning-default-value-to-autocomplete-in-materialui-and-react-js
            options={options}
            loading={loading}
            onChange={(event, value) => {
                //   setCountMill(value.name);
                //   setMillID(value.id);
                //   setGetID(value.id);
                //   setPointerIsURL(false);
                getDataFromDatabase(value.id);
                //  //console.log("open id" +value.id)
                //    var link = "manuelSearch?name="+value.name
                //    var win = window.open(link, '_self');
                //   win.focus();
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Choose technology"
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );


    let contentPicture = "";
    if (exists_picture) {

        var pathPicture = "/TechnologyInformation/" + mill_id + "/picture.jpg";

        contentPicture = (<td width="40%">
            <img src={pathPicture} />
        </td>)

    }




    let contentReturn = (
        <div>
            <table width="100%">
                <tr>
                    <td valign="top"></td>
                </tr>

                <tr>
                    <td valign="top" width="60%">
                        <p>{description}</p>
                    </td>

                    <td>  </td>
                    {contentPicture}
                </tr>
            </table>
            <br />
            <table width="100%">
                <tr>
                    <td width="30%">
                        <p>Operation mode:</p>
                    </td>
                    <td width="100%" valign="top">
                        <p>{circuit}, {operation_mode}</p>
                    </td>
                </tr>

                <tr>
                    <td width="30%">
                        <p>Material hardness:</p>
                    </td>
                    <td width="100 %" valign="top">
                        <p>{material_hardness}</p>
                    </td>
                </tr>

                <tr>
                    <td width="30%">
                        <p>Technology category:</p>
                    </td>
                    <td width="100%" valign="top">
                        <p>{mill_category}</p>
                    </td>
                </tr>

                <tr>
                    <td width="30%">
                        <p>Industry sector:</p>
                    </td>
                    <td width="100%" valign="top">
                        <p>{industry_sector}</p>
                    </td>
                </tr>
            </table>
        </div>
    );





    let contentTrials;
    //console.log("GrindingTrial_mill_name: " + GrindingTrial_mill_name)
    if (trial_info != null && trial_info.length > 0 && millInfo != "" && GrindingTrial_mill_name != null) {

        var labelsTrials = [];
        var xdata = [];
        var ydata = [];


        //if (data_Surface != null && data_Surface.length > 0) {

        //}
        //if (data_Fineness != null && data_Fineness.length > 0) {

        //}
        //if (data_PSD != null && data_PSD.length > 0) {

        //}
        //if (data_Throughput != null && data_Throughput.length > 0) {

        //}
        //if (data_Energy != null && data_Energy.length > 0) {


        //} 




        var xLabel = ['Spec. surface acc. to Blaine in cm²/g']
        var yLabel = ['Spec. energy demand in kWh/t']
        var isDataAvailable = "";
        // Select Data
        if (valueXAxes == "Spec. energy demand") {

            if (data_Energy != null && data_Energy.length > 0 && data_Energy != "nan") {
                xdata = data_Energy;
                xLabel = ['Spec. energy demand in kWh/t']
                isDataAvailable = ""

            } else {
                isDataAvailable = "No data available"
            }
        } else if (valueXAxes == "Fineness") {

            if (data_Fineness != null && data_Fineness.length > 0 && data_Fineness != "nan") {
                xdata = data_Fineness;
                xLabel = data_finenessDescription
                isDataAvailable = ""
            } else {
                isDataAvailable = "No data available"
            }
        } else if (valueXAxes == "Width of PSD") {
            if (data_PSD != null && data_PSD.length > 0 && data_PSD != "nan") {
                xdata = data_PSD;
                xLabel = ['RRSB n']
                isDataAvailable = ""
            } else {
                isDataAvailable = "No data available"
            }
        } else if (valueXAxes == "Throughput") {
            if (data_Throughput != null && data_Throughput.length > 0 && data_Throughput != "nan") {
                xdata = data_Throughput;
                xLabel = ['Throughput in t/h']
                isDataAvailable = ""
            } else {
                isDataAvailable = "No data available"
            }
        } else {
            if (data_Surface != null && data_Surface.length > 0 && data_Surface != "nan") {
                xdata = data_Surface;
                xLabel = ['Spec. surface acc. to Blaine in cm²/g']
                isDataAvailable = ""
            } else {
                isDataAvailable = "No data available"
            }
        }

        //console.log("data_Throughput"+data_Throughput)

        if (valueYAxes == "Spec. surface acc. to Blaine in cm²/g") {
            ydata = data_Surface;
            yLabel = ['Spec. surface acc. to Blaine in cm²/g']
        } else if (valueYAxes == "Fineness") {
            ydata = data_Fineness;
            yLabel = ['D50 in µm']
        } else if (valueYAxes == "Width of PSD") {
            ydata = data_PSD;
            yLabel = ['RRSB n']
        } else if (valueYAxes == "Throughput") {
            ydata = data_Throughput;
            yLabel = ['Throughput in t/h']
        } else {

            ydata = data_Energy;
            yLabel = ['Spec. energy demand in kWh/t']
        }
        //console.log(valueXAxes)










        var dataset = []
        var d = []

        var borderdash = [[0, 0], [10, 5], [2, 2]]
        var bd = '';
        var c = '';


        var colour = [
            'rgb(0, 87, 157)',
            'rgb(95, 82, 78)',
            'rgb(250, 175, 20)',
            'rgb(98, 132, 188)',
            'rgb(255, 26, 0)',
            'rgb(158, 141, 136)',
            'rgb(0, 152, 135)',
            'rgb(164, 184, 216)',
            'rgb(193, 182, 179)',
            'rgb(0, 0, 0)',
        ]

        var ic = -1;

        for (var i = 0; i < GrindingTrial_id.length; i++) {

            d = []


            // for (var ii = 0; ii < xdata.length; ii++) {
            //  d.push({ x: xdata[i].map(x => +x), y: ydata[i].map(x => +x) });
            //}
            if (xdata[i] != null && xdata[i].length > 0) {

                for (var ii = 0; ii < xdata[i].length - 1; ii++) {
                    d.push({ x: String(xdata[i]).split`,`.map(x => +x)[ii], y: String(ydata[i]).split`,`.map(x => +x)[ii] });
                }



                var GrindingTrials_GrindingAid_sign = "";
                if (GrindingTrials_GrindingAid.split(";")[i] == 1.0) {
                    GrindingTrials_GrindingAid_sign = "*";
                }

                var tmpSplit = GrindingTrial_DevelopmentState.split(";")[i];
                var GrindingTrial_DevelopmentState_sign = "a";
                if (tmpSplit == 2) {
                    GrindingTrial_DevelopmentState_sign = "b";
                } else if (tmpSplit == 3) {
                    GrindingTrial_DevelopmentState_sign = "c";
                } else if (tmpSplit == 4) {
                    GrindingTrial_DevelopmentState_sign = "d";
                }





                labelsTrials[i] = GrindingTrial_id[i] + ' - ' + GrindingTrial_mill_name.split(";")[i] + ': ' + GrindingTrial_Material.split(";")[i] + ', FeedD50% ' + precise(GrindingTrial_feedSize.split(";")[i]) + 'mm ' + GrindingTrial_OperationMode.split(";")[i] + ') ' + GrindingTrial_DevelopmentState_sign + ') ' + GrindingTrials_GrindingAid_sign;

                if (i <= colour.length) {
                    bd = borderdash[0];

                } else if (i > colour.length) {
                    bd = borderdash[1];

                } else if (i > colour.length * 2) {
                    bd = borderdash[2];

                }


                if (ic > colour.length) {
                    ic = 0;
                } else {
                    ic = ic + 1;
                }


                dataset.push({
                    label: labelsTrials[i],
                    data: d,
                    showLine: true,
                    fill: false,

                    borderDash: bd,
                    borderColor: colour[ic],
                    pointBackgroundColor: colour[ic],
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: colour[ic]
                })
            }
        }


        const data = {
            labels: "",
            datasets: [],
        };

        data.datasets = dataset;

        var dataAvailable = true;
        if (data.datasets.length == 0) {

            dataAvailable = false;

        } else {
            dataAvailable = true;
        }




        const options = {
            legend: {
                display: true,
                position: 'bottom',
            },
            scales: {
                yAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: yLabel
                    }
                }],
                xAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: xLabel
                    }
                }]

            },

            tooltips: {

                callbacks: {
                    title: function (tooltipItem) {
                        return yLabel + " /" + xLabel;
                    },

                    label: function (tooltipItem) {
                        return Number(tooltipItem.yLabel) + " / " + Number(tooltipItem.xLabel);
                    }
                }
            }





        };

        // const optionsXAxes = ['Spec. energy demand', 'Fineness', 'Spec. surface acc. to Blaine in cm²/g', 'Width of PSD', 'Throughput'];




        contentTrials = (
            <div >
                <br /><br />
                <b>Results from grinding trials</b><br />


                <table width="100%"><tr><td width="50%" align="center">
                    <br />
                    <Autocomplete
                        value={valueXAxes}
                        onChange={(event, newValue) => {
                            setValueXAxes(newValue);
                        }}
                        inputValue={inputValueXAxes}
                        onInputChange={(event, newInputValue) => {
                            setInputValueXAxes(newInputValue);
                        }}
                        id="controllable-states-demo"

                        options={optionsXAxes}
                        style={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="X-axes data" variant="outlined" />}
                    />
                </td><td width="50%" align="center">
                        <br />
                        <Autocomplete
                            value={valueYAxes}
                            onChange={(event, newValue) => {
                                setValueYAxes(newValue);
                            }}
                            inputValue={inputValueYAxes}
                            onInputChange={(event, newInputValue) => {
                                setInputValueYAxes(newInputValue);
                            }}
                            id="controllable-states-demo"

                            options={optionsXAxes}
                            style={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} label="Y-axes data" variant="outlined" />}
                        />
                    </td></tr></table>


                <br />

                <center>
                    {isDataAvailable}
                    <Scatter data={data} options={options} />


                </center>
                <br />

                        Please note annotations:

                <table width="100%"><tr><td width="5%" valign="top" align="right">
                    <p class="smallfont">
                        0)<br />
                        1)<br />
                        2)<br />
                        3)<br />
                        4)<br />
                        5)<br />
                        6)<br />
                    </p>
                </td><td width="28%" valign="top">

                        <p class="smallfont">
                            Batch<br />
                        OC<br />
                        CC - Screen<br />
                         CC - Static Separator<br />
                         CC - Separator 1.  generation <br />
                         CC - Separator 2.  generation <br />
                         CC - Separator 3. generation <br /><br />
                                OC = Open Circuit<br />
                                CC = Closed Circuit<br />
                        </p>
                    </td><td width="5%" valign="top" align="right">
                        <p class="smallfont">
                            a)<br />
                        b)<br />
                        c)<br />
                        d)<br />

                        </p>
                    </td><td width="40%" valign="top">

                        <p class="smallfont">
                            Laboratory<br />
                            	Semi-Industrial / technical scale<br />
                            	Prototype cement industry<br />
                            	Industrial production cement industry<br />

                        </p>
                    </td><td width="23%" valign="top">

                        <p class="smallfont">
                            *Grinding aids used
                            </p>
                    </td></tr></table>




            </div>
        )
    }


    let contentReportTrials;

    if (exists_report) {

        var pathReport =
            "/TechnologyInformation/" + mill_id + "/report_trial.pdf";



        contentReportTrials = (
            <div><br />
                <center>
                    <a target="_blank" href={pathReport}>Download short report</a><br />
                            Trials conducted by {trial_info}
                    <br /><br /><br />

                </center>

            </div >
        )
    }

    let industrialScaleSlider;


    var trl_calc = 100 / 8 * (industrialScale - 1);

    if (industrialScale > 0) {


        const marksScale = [
            {
                value: 0,
                label: "1",
            },
            {
                value: (1 * 100) / 8,
                label: "2",
            },
            {
                value: (2 * 100) / 8,
                label: "3",
            },
            {
                value: (3 * 100) / 8,
                label: "4",
            },
            {
                value: (4 * 100) / 8,
                label: "5",
            },
            {
                value: (5 * 100) / 8,
                label: "6",
            },
            {
                value: (6 * 100) / 8,
                label: "7",
            },
            {
                value: (7 * 100) / 8,
                label: "8",
            },
            {
                value: (8 * 100) / 8,
                label: "9",
            },
        ];

        var trlDescription;

        if (industrialScale == 1) {
            trlDescription = "TRL 1 – Basic principles observed";

        } else if (industrialScale == 2) {
            trlDescription = "TRL 2 – Technology concept formulated";

        } else if (industrialScale == 3) {
            trlDescription = "TRL 3 – Experimental proof of concept";

        } else if (industrialScale == 4) {
            trlDescription = "TRL 4 – Technology validated in lab";

        } else if (industrialScale == 5) {
            trlDescription =
                "TRL 5 – Technology validated in relevant environment (industrially relevant environment in the case of key enabling technologies)";

        } else if (industrialScale == 6) {
            trlDescription =
                "TRL 6 – Technology demonstrated in relevant environment (industrially relevant environment in the case of key enabling technologies)";

        } else if (industrialScale == 7) {
            trlDescription =
                "TRL 7 – System prototype demonstration in operational environment";

        } else if (industrialScale == 8) {
            trlDescription = "TRL 8 – System complete and qualified";

        } else if (industrialScale == 9) {
            trlDescription =
                "TRL 9 – Actual system proven in operational environment (competitive manufacturing in the case of key enabling technologies; or in space)";

        }

        trl_calc = 100 / 8 * (industrialScale - 1);


        industrialScaleSlider = (
            <div>
                <br />
                <br />
                <b>Technology Readyness Level (TRL EU)</b>
                <br />
                <table width="90%" align="center">
                    <tr>
                        <td>
                            <CustomSlider
                                defaultValue={trl_calc}
                                value={trl_calc}
                                valueLabelDisplay="auto"
                                marks={marksScale}
                                disabled={1}
                            />

                            <br />
                            {trlDescription}
                            <br />
                            <br />
                        </td>
                    </tr>
                </table>
            </div>
        );
    }

    let accuracyIndexSlider;

    const marksAccuracy = [
        {
            value: 0,
            label: "No data available",
        },
        {
            value: 33,
            label: "Expert estimation",
        },
        {
            value: 66,
            label: "Literature study",
        },
        {
            value: 100,
            label: "Grinding trials",
        },
    ];








    if (accuracyIndex) {
        accuracyIndexSlider = (
            <div>
                <br />
                <br />
                <b>Accuracy Index</b>
                <br />
                <table width="90%" align="center">
                    <tr>
                        <td>

                            <CustomSlider
                                defaultValue={accuracyIndex}
                                value={accuracyIndex}
                                valueLabelDisplay="auto"
                                marks={marksAccuracy}
                                disabled={1}
                            />
                            <br />
                            <br />
                            <br />
                        </td>
                    </tr>
                </table>
            </div>
        );
    }

    var pathFunctionPicture =
        "/TechnologyInformation/" + mill_id + "/function.jpg";

    let contentFunctionPicture;
    if (exists_picture_function) {
        contentFunctionPicture = (
            <div >
                <br /><br />
                <b> Function principle</b><br /><br />
                <center>
                    <img title={source_FunctionPicture} src={pathFunctionPicture} />
                </center>
                <br /><br />
            </div>
        );
    }

    let contentVideo;
    if (video_link != null && video_link.length > 0) {
        contentVideo = (
            <div >
                <center><a href={video_link} target="_blank"><b><span><OndemandVideoIcon /> Link to video</span></b><b> (external)</b></a></center>
                <br /><br />
            </div>
        );
    }

    let contentSource;
    //console.log("source:" + source)
    if (source != null && source.length > 0) {
        contentSource = (
            <div>
                <br />
                Source:
                <br />
                {source}
                <br />
            </div>
        );
    }

    let contentSourcePicture;
    if (source_picture != null && source_picture.length > 0) {
        contentSourcePicture = (
            <div>
                <br />
                Source picture:
                <br />
                {source_picture}
                <br />
            </div>
        );
    }

    let contentSourceFunctionPicture;
    if (source_FunctionPicture != null && source_FunctionPicture.length > 0) {
        contentSourceFunctionPicture = (
            <div>
                <br />
                Source function-picture:
                <br />
                {source_FunctionPicture}
                <br />
            </div>
        );
    }

    let contentManufacturers;
    if (manufacturers != null && manufacturers.length > 0) {
        contentManufacturers = (
            <div>
                <br />
                Manufacturers:
                <br />
                {manufacturers}
                <br />
            </div>
        );
    }




    useEffect(() => {
        let active = true;

        //if (!loading) {
        //    return [];
        //}

        (async () => {

            const mills = await fetch("api/mills")
                .then(response => {
                    return response.json();
                })

            if (active) {
                setOptions(Object.keys(mills).map((id) => mills[id]));
                setGetID(Object.keys(mills).map((id) => mills[id]));
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    var contentKeywords;
    if (keywords != null) {
        contentKeywords = (
            <div>
                <br />
                Keywords:
                <br />
                {keywords}
            </div>
        )
    }


    var mailSubject = "mailto:fgt-support@ecra-online.org?subject=Ecra-FGT_Feedback " + mill_name;


    return (
        <div id="styles">
            <div class="container">
                <h2>ECRA Technology Database</h2>
                <br />
                {autoCompleteReturn}
                <br />
                <table width="100%"><tr><td align="left" valign="top">
                    <h2>{mill_name} </h2></td><td align="right">


                        <a href={mailSubject}>
                            Feedback </a>
                    </td><td align="right" width="5%">
                        <a href={mailSubject}>
                            <IconFormular /> </a>



                    </td></tr></table>
                {contentReturn}
                {contentFunctionPicture}
                {contentTrials}
                {contentReportTrials}
                {industrialScaleSlider}
                {accuracyIndexSlider}
                {contentVideo}
                {contentSource}
                {contentSourcePicture}
                {contentSourceFunctionPicture}
                {contentManufacturers}
                {contentKeywords}
                <br />
                <br />
                Last update of data: {dateOfUpdate}
            </div>
        </div>
    );

}

export default ManuelSearch;
