import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";

// Templates from: https://material-ui.com/api/slider/
// https://www.youtube.com/watch?v=6IYo0uBew1c

//Quality
const marks01 = [
    { value: 0, label: "0 %" },
    { value: 25, label: "25 %" },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 35, label: <strong>Pre</strong> },
];
const marks02 = [
    { value: 0, label: "0 %" },
    { value: 25 },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 30, label: <strong>Pre</strong> },
];
const marks03 = [
    { value: 0, label: "0 %" },
    { value: 25 },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 20, label: <strong>Pre</strong> },
];
const marks04 = [
    { value: 0, label: "0 %" },
    { value: 25, label: "25 %" },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 15, label: <strong>Pre</strong> },
];

//Feed / Flexibility
const marks05 = [
    { value: 0, label: "0 %" },
    { value: 25, label: "25 %" },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 60, label: <strong>Pre</strong> },
];
const marks06 = [
    { value: 0, label: "0 %" },
    { value: 25, label: "25 %" },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 40, label: <strong>Pre</strong> },
];

//Operation
const marks07 = [
    { value: 0, label: "0" },
    { value: 25, label: "25 %" },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 5, label: <strong>Pre</strong> },
];
const marks08 = [
    { value: 0, label: "0 %" },
    { value: 25 },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 25, label: <strong>Pre</strong> },
];
const marks09 = [
    { value: 0, label: "0 %" },
    { value: 25 },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 20, label: <strong>Pre</strong> },
];
const marks10 = [
    { value: 0, label: "0 %" },
    { value: 25 },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 20, label: <strong>Pre</strong> },
];
const marks11 = [
    { value: 0, label: "0 %" },
    { value: 25 },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 30, label: <strong>Pre</strong> },
];

//Maintenance
const marks12 = [
    { value: 0, label: "0 %" },
    { value: 25, label: "25 %" },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 40, label: <strong>Pre</strong> },
];
const marks13 = [
    { value: 0, label: "0 %" },
    { value: 25 },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 20, label: <strong>Pre</strong> },
];
const marks14 = [
    { value: 0, label: "0 %" },
    { value: 25, label: "25 %" },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 40, label: <strong>Pre</strong> },
];

//Efficiency
const marks15 = [
    { value: 0, label: "0 %" },
    { value: 25, label: "25 %" },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100 },
    { value: 95, label: <strong>Pre</strong> },
];
const marks16 = [
    { value: 0 },
    { value: 25, label: "25 %" },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 5, label: <strong>Pre</strong> },
];

//Environmental Impact
const marks17 = [
    { value: 0, label: "0 %" },
    { value: 25 },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 30, label: <strong>Pre</strong> },
];
const marks18 = [
    { value: 0, label: "0 %" },
    { value: 25, label: "25 %" },
    { value: 50 },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 50, label: <strong>Pre</strong> },
];
const marks19 = [
    { value: 0, label: "0 %" },
    { value: 25 },
    { value: 50, label: "50 %" },
    { value: 75, label: "75 %" },
    { value: 100, label: "100 %" },
    { value: 20, label: <strong>Pre</strong> },
];

const useStyles = makeStyles({
    root: {
        width: 300,
    },
});

export default function SliderCritDefault() {
    const classes = useStyles();
    const [valuePSD, setValuePSD] = useState(35);
    const [valueMaxFineness, setValueMaxFineness] = useState(30);
    const [valueDehydration, setValueDehydration] = useState(20);
    const [valueDrying, setValueDrying] = useState(15);
    const [valueMaxFeedSize, setValueMaxFeedSize] = useState(60);
    const [valueProductChangingTime, setValueProductChangingTime] = useState(
        40
    );
    const [
        valueUnderstandingOfProcess,
        setValueUnderstandingOfProcess,
    ] = useState(5);
    const [valueSensitivityOfProcess, setValueSensitivityOfProcess] = useState(
        25
    );
    const [
        valueControlabilityPersonnel,
        setValueControlabilityPersonnel,
    ] = useState(20);
    const [valueAvailableExperience, setValueAvailableExperience] = useState(
        20
    );
    const [valueThroughput, setValueThroughput] = useState(30);
    const [valueMaintenanceTime, setValueMaintenanceTime] = useState(40);
    const [
        valueComplexityOfMaintenance,
        setValueComplexityOfMaintenance,
    ] = useState(20);
    const [valueWearCosts, setValueWearCosts] = useState(40);
    const [valueEnergyDemand, setValueEnergyDemand] = useState(95);
    const [valueWaterDemand, setValueWaterDemand] = useState(5);
    const [valueDustEmissions, setValueDustEmissions] = useState(30);
    const [valueNoise, setValueNoise] = useState(50);
    const [valueEffectOfVibration, setValueEffectOfVibration] = useState(20);

    const handleChangePSD = (e, newValue) => {
        setValuePSD(newValue);
    };

    const handleChangeMaxFineness = (e, newValue) => {
        setValueMaxFineness(newValue);
    };

    const handleChangeDehydration = (e, newValue) => {
        setValueDehydration(newValue);
    };

    const handleChangeDrying = (e, newValue) => {
        setValueDrying(newValue);
    };

    const handleChangeMaxFeedSize = (e, newValue) => {
        setValueMaxFeedSize(newValue);
    };

    const handleChangeProductChangingTime = (e, newValue) => {
        setValueProductChangingTime(newValue);
    };

    const handleChangeUnderstandingOfProcess = (e, newValue) => {
        setValueUnderstandingOfProcess(newValue);
    };

    const handleChangeSensitivityOfProcess = (e, newValue) => {
        setValueSensitivityOfProcess(newValue);
    };

    const handleChangeControlabilityPersonnel = (e, newValue) => {
        setValueControlabilityPersonnel(newValue);
    };

    const handleChangeAvailableExperience = (e, newValue) => {
        setValueAvailableExperience(newValue);
    };

    const handleChangeThroughput = (e, newValue) => {
        setValueThroughput(newValue);
    };

    const handleChangeMaintenanceTime = (e, newValue) => {
        setValueMaintenanceTime(newValue);
    };

    const handleChangeComplexityOfMaintenance = (e, newValue) => {
        setValueComplexityOfMaintenance(newValue);
    };

    const handleChangeWearCosts = (e, newValue) => {
        setValueWearCosts(newValue);
    };

    const handleChangeEnergyDemand = (e, newValue) => {
        setValueEnergyDemand(newValue);
    };

    const handleChangeWaterDemand = (e, newValue) => {
        setValueWaterDemand(newValue);
    };

    const handleChangeDustEmissions = (e, newValue) => {
        setValueDustEmissions(newValue);
    };

    const handleChangeNoise = (e, newValue) => {
        setValueNoise(newValue);
    };

    const handleChangeEffectOfVibration = (e, newValue) => {
        setValueEffectOfVibration(newValue);
    };

    return (
        <div>
            <h3>Quality</h3>
            <Typography id="PSD" gutterBottom>
                Slope of Particle Size Distribution: {valuePSD}
            </Typography>
            <Slider
                defaultValue={valuePSD}
                aria-labelledby="PSD"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={marks01}
                disabled={1}
                onChange={handleChangePSD}
            />

            <Typography id="MaxFineness" gutterBottom>
                Maximum Fineness: {valueMaxFineness}
            </Typography>
            <Slider
                defaultValue={valueMaxFineness}
                aria-labelledby="Max. Fineness"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={marks02}
                disabled={1}
                onChange={handleChangeMaxFineness}
            />

            <Typography id="Dehydration" gutterBottom>
                Dehydration: {valueDehydration}
            </Typography>
            <Slider
                defaultValue={valueDehydration}
                aria-labelledby="Dehydration"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={marks03}
                disabled={1}
                onChange={handleChangeDehydration}
            />
            <Typography id="Drying" gutterBottom>
                Drying: {valueDrying}
            </Typography>
            <Slider
                defaultValue={valueDrying}
                aria-labelledby="Drying"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={marks04}
                disabled={1}
                onChange={handleChangeDrying}
            />

            <br />
            <br />
            <h3>Feed / Flexibility</h3>

            <Typography id="MaxFeedSize" gutterBottom>
                Max. Feed Size: {valueMaxFeedSize}
            </Typography>
            <Slider
                defaultValue={valueMaxFeedSize}
                aria-labelledby="Max. Feed Size"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={marks05}
                disabled={1}
                onChange={handleChangeMaxFeedSize}
            />
            <Typography id="ProductChangingTime" gutterBottom>
                Product Changing Time: {valueProductChangingTime}
            </Typography>
            <Slider
                defaultValue={valueProductChangingTime}
                aria-labelledby="Product Changing Time"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={marks06}
                disabled={1}
                onChange={handleChangeProductChangingTime}
            />

            <br />
            <br />
            <h3>Operation</h3>

            <Typography id="UnderstandingOfProcess" gutterBottom>
                Understanding of Process: {valueUnderstandingOfProcess}
            </Typography>
            <Slider
                defaultValue={valueUnderstandingOfProcess}
                aria-labelledby="Understanding of Process"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={marks07}
                disabled={1}
                onChange={handleChangeUnderstandingOfProcess}
            />
            <Typography id="Sensitivity of Process" gutterBottom>
                Sensitivity of Process: {valueSensitivityOfProcess}
            </Typography>
            <Slider
                defaultValue={valueSensitivityOfProcess}
                aria-labelledby="Sensitivity of Process"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={marks08}
                disabled={1}
                onChange={handleChangeSensitivityOfProcess}
            />
            <Typography id=" Controlability/Personnel" gutterBottom>
                Controlabillity/ Personnel: {valueControlabilityPersonnel}
            </Typography>
            <Slider
                defaultValue={valueControlabilityPersonnel}
                aria-labelledby="Controlability/Personnel"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={marks09}
                disabled={1}
                onChange={handleChangeControlabilityPersonnel}
            />
            <Typography id="AvailableExperience" gutterBottom>
                Available Experience: {valueAvailableExperience}
            </Typography>
            <Slider
                defaultValue={valueAvailableExperience}
                aria-labelledby="Available Experience"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={marks10}
                disabled={1}
                onChange={handleChangeAvailableExperience}
            />
            <Typography id="Throughput" gutterBottom>
                Throughput: {valueThroughput}
            </Typography>
            <Slider
                defaultValue={valueThroughput}
                aria-labelledby="Throughput"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={marks11}
                disabled={1}
                onChange={handleChangeThroughput}
            />

            <br />
            <br />
            <h3>Maintenance</h3>

            <Typography id="MaintenanceTime" gutterBottom>
                Maintenance Time: {valueMaintenanceTime}
            </Typography>
            <Slider
                defaultValue={valueMaintenanceTime}
                aria-labelledby="Maintenance Time"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={marks12}
                disabled={1}
                onChange={handleChangeMaintenanceTime}
            />
            <Typography id="ComplexityOfMaintenance" gutterBottom>
                Complexity of Maintenance: {valueComplexityOfMaintenance}
            </Typography>
            <Slider
                defaultValue={valueComplexityOfMaintenance}
                aria-labelledby="Complexity of Maintenance"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={marks13}
                disabled={1}
                onChange={handleChangeComplexityOfMaintenance}
            />
            <Typography id="WearCosts" gutterBottom>
                Wear Costs: {valueWearCosts}
            </Typography>
            <Slider
                defaultValue={valueWearCosts}
                aria-labelledby="Wear Costs"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={marks14}
                disabled={1}
                onChange={handleChangeWearCosts}
            />

            <br />
            <br />
            <h3>Efficiency</h3>

            <Typography id="EnergyDemand" gutterBottom>
                Energy Demand: {valueEnergyDemand}
            </Typography>
            <Slider
                defaultValue={valueEnergyDemand}
                aria-labelledby="Energy Demand"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={marks15}
                disabled={1}
                onChange={handleChangeEnergyDemand}
            />
            <Typography id="WaterDemand" gutterBottom>
                Water Demand: {valueWaterDemand}
            </Typography>
            <Slider
                defaultValue={valueWaterDemand}
                aria-labelledby="Water Demand"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={marks16}
                disabled={1}
                onChange={handleChangeWaterDemand}
            />

            <br />
            <br />
            <h3>Environmental Impact</h3>

            <Typography id="DustEmissions" gutterBottom>
                Dust Emissions: {valueDustEmissions}
            </Typography>
            <Slider
                defaultValue={valueDustEmissions}
                aria-labelledby="Dust Emissions"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={marks17}
                disabled={1}
                onChange={handleChangeDustEmissions}
            />
            <Typography id="Noise" gutterBottom>
                Noise: {valueNoise}
            </Typography>
            <Slider
                defaultValue={valueNoise}
                aria-labelledby="Noise"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={marks18}
                disabled={1}
                onChange={handleChangeNoise}
            />
            <Typography id="EffectofVibration" gutterBottom>
                Effect of Vibration: {valueEffectOfVibration}
            </Typography>
            <Slider
                defaultValue={valueEffectOfVibration}
                aria-labelledby="Effect of Vibration"
                step={5}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                marks={marks19}
                disabled={1}
                onChange={handleChangeEffectOfVibration}
            />
            <p style={{ color: "red" }}>Warning: </p>
            <p>
                Do not apply more or less than 100 % <em>(Work in progress)</em>
            </p>
        </div>
    );
}
