import React, { useState } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Paper, Tab, Tabs } from "@material-ui/core";
import InfoButtonApp from "./infoButton_App";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

const useStyles = makeStyles((theme) => ({
    root1: {
        display: "flex",
        width: "40px",
        margin: "auto",
        flexWrap: "wrap",
        "& > *": {
            width: theme.spacing("auto"),
            height: theme.spacing("auto"),
        },
    },
    root2: {
        justifyContent: "center",
        display: "flex",
        flexWrap: "wrap",
        "& > *": {
            width: theme.spacing(120),
            height: theme.spacing("auto"), 
        },
    },
    root3: {
        justifyContent: "center",
    },

    inner: {
        textAlign: "justify",
        justifyContent: "center",
        display: "flex",
        flexWrap: "wrap",
        width: "90%",
    },

    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

export default function InfoButtonSce() {
    const [value, setValue] = useState();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen(!open);
    };

    return (
        <div>
            <IconButton
                className={classes.root1}
                variant="outlined"
                color="#ccc"
                onClick={handleToggle}
            >
                <HelpOutlineIcon fontSize="large" />
            </IconButton>
            <Backdrop
                className={classes.backdrop}
                open={open}
                onClick={handleClose}
                transitionDuration={425}
            >
                {/* <CircularProgress color="inherit" /> */}
                <div className={classes.root2}>
                    <Paper elevation={24} square={false}>
                        <hr />
                        <h3>  Scenarios: </h3>
                        <hr />
                        <center>
                        <div className={classes.inner}>
                        To evaluate various comminution technologies to a
                        cement-based reference, ECRA’s grinding expert team has
                        identified 6 different categories, which consist of
                        ‘quality’, ‘feed / flexibility’, ‘operation’,
                        ‘maintenance’, ‘efficiency’ and ‘environmental impact’.<br />
                        Each of the categories is subdivided into several
                        different criteria to perform a more detailed
                        evaluation. The categories and related criteria are
                        described below. It should be noted, that CAPEX and OPEX
                        are deliberately excluded from the evaluation procedure
                        in order to avoid a too strong influence on the
                        evaluation results due to economical related or
                        cost-driven aspects. The implementation of the criterion
                        ‘wear costs’ is the only exception regarding cost
                        specific evaluation factors. <br /> <br />
                        To start an evaluation, it is necessary to define the
                        importance for each category and the related subdivided
                        criteria. The importance settings for each of the
                        categories and criteria – expressed as percentage
                        portion – can easily be chosen from default options like
                        for example the ‘European standard setting’. In this
                        case, no precise knowledge is necessary to run the
                        evaluation procedure. As you decide for a predefined
                        scenario (e.g. ‘European standard setting’), all
                        categories and criteria are assigned to a default
                        importance setting. Please note, that all carefully
                        predefined options are the result of decisions made by
                        ECRA’s grinding experts. <br /> <br />
                        However, if ‘customised setting’ is selected, the user
                        is able to define individual importance settings to
                        create an evaluation scenario based on personal
                        experience and requirements. In this case, further
                        information is given in the description of the
                        ‘customised setting’ option to run a smooth evaluation.
                                </div>
                            </center>
                        <hr />
                    </Paper>

                    {/* <Paper>
                        <br />

                        <Tabs
                            value={value}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={handleChange}
                        >
                            <Tab label="Active" />
                            <Tab label="Disabled" />
                            <Tab label="Active" />
                        </Tabs>
                    </Paper> */}
                </div>
            </Backdrop>
        </div>
    );
}
