import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";

// Icon Quelle: https://material-ui.com/components/material-icons/
// Seitenquelle: view-source:https://material-ui.com/components/material-icons/

const useStyles = makeStyles((theme) => ({
    root: {
        "& > svg": {
            margin: theme.spacing(1),
        },
    },
}));

function IconAllMillsSelected(props) {
    return (
        <SvgIcon {...props}>
            <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27-7.38 5.74zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16z" />
        </SvgIcon>
    );
}
function IconAllMillsSelected2(props) {
    return (
        <SvgIcon {...props}>
            <path d="M18 7l-1.41-1.41-6.34 6.34 1.41 1.41L18 7zm4.24-1.41L11.66 16.17 7.48 12l-1.41 1.41L11.66 19l12-12-1.42-1.41zM.41 13.41L6 19l1.41-1.41L1.83 12 .41 13.41z" />
        </SvgIcon>
    );
}

export default function IconStart() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <IconAllMillsSelected style={{ fontSize: 60 }} />
            <IconAllMillsSelected2 style={{ fontSize: 60 }} />
        </div>
    );
}
