import React from "react";
//import Clock from "../Container/clock";
//import IconSearch from "../Container/Icons/iconDatabase";
//import IconStart from "../Container/Icons/iconStart";
//import IconFormular from "../Container/Icons/iconFormular";
class Header extends React.Component {
    render() {
        return (
            <header>
         
                <div class="container">


                        <a href="/startscreen" >
                    <img height="80"  src="/image/ecra_logo.png"/> 
                    </a>
          
                </div>
         

            </header>
        );
    }
}

export default Header;
