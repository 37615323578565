import React, { useState } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Paper, Tab, Tabs } from "@material-ui/core";
import InfoButtonApp from "./infoButton_App";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

const useStyles = makeStyles((theme) => ({
    root1: {
        display: "flex",
        //width: "40px",
        margin: "auto",
        flexWrap: "wrap",
        "& > *": {
            width: theme.spacing("auto"),
            height: theme.spacing("auto"),
        },
    },
    root2: {
        justifyContent: "center",
        display: "flex",
        flexWrap: "wrap",
        "& > *": {
            width: theme.spacing(120),
            height: theme.spacing("auto"),
        },
    },
    root3: {
        justifyContent: "center",
    },


    inner: {
        textAlign: "justify",
        justifyContent: "center",
        display: "flex",
        flexWrap: "wrap",
        width: "90%",
    },

    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

export default function InfoButtonMil() {
    const [value, setValue] = useState();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen(!open);
    };

    return (
        <div>
            <IconButton
                className={classes.root1}
                variant="outlined"
                color="#ccc"
                onClick={handleToggle}
            >
                <HelpOutlineIcon fontSize="large" />
            </IconButton>
            <Backdrop
                className={classes.backdrop}
                open={open}
                onClick={handleClose}
                transitionDuration={425}
            >
                {/* <CircularProgress color="inherit" /> */}
                <div className={classes.root2}>
                    <Paper elevation={24} square={false}>
                        <hr />
                        <h3>  Technology Selection: </h3>
                        <hr />


                        <center>
                            <div className={classes.inner}>

                        After all scenario settings have been defined, there are
                        two options how to compare different comminution
                        technologies. The first option is “Select all suitable technologies (Filters applied)”,
                        means, that all suitable comminution technologies listed in
                        ECRA’s Technology Database will be involved in the
                        evaluation process. Please note, that all filed
                        technologies in this case will be compared in relation
                        to a cement-based reference, carefully defined by ECRA’s
                        grinding experts.<br /> The second option “Choose 
                        individually (Inactive filters)” provides
                        the possibility to select interesting technologies
                        individually and to evaluate the defined selection in
                        respect to either the cement-based reference or a
                        self-selected technology type. The individual selection
                        is supported via filter-function to automatically
                        exclude technology types, which do not match the user’s
                        interest. All resulting comminution technologies are
                        displayed in the box for further selection.
                        <br /> <br />
                        To start the evaluation based on the previous
                        selections, please press the “Start evaluation” button below. The evaluation takes
                        a few moments, results will be shown in a separate
                        window. <br />Please note that all chosen selections will be
                        saved during one session and will be reset after logout.
                        The results from evaluation can be converted to
                        Microsoft EXCEL© and saved for further use. Published
                        results should be cited using ECRA’s citation
                        modalities. <br /> <br />


                            </div>
                        </center>
                        <center>
                            Problems with evaluation procedure? Please contact
                            ECRA’s Technical Support: 
                             
                        <a href="mailto:fgt-support@ecra-online.org?subject=Ecra-FGT%20Problems%20with%20evaluation%20procedure">
                            <p style={{ color: "#104f94" }}>
                                fgt-support@ecra-online.org
                            </p>
                            </a>
                            </center>
                                <hr />



                    </Paper>
                </div>
            </Backdrop>
        </div>
    );
}
