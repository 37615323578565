//import { Button } from "@material-ui/core";
import React from "react";
import InfoButtonGui from "../../../../Container/InfoButton/infoButton_Gui";

export default function ArticelGuideline() {
    return (
        <article id="Guideline">
            <section className>
                <h2>User Guide</h2>
                <InfoButtonGui />
                <p>
                    {/* Old Version: 
                    Use Icon above to have a look at the instruction manual for each Article. */}
                    Click on icon above to view the user guide for ECRA`s
                    Technology-Evaluation-Tool.
                </p>
            </section>
        </article>
    );
}
