import React, { useState } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Paper, Tab, Tabs } from "@material-ui/core";
import InfoButtonApp from "./infoButton_App";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

const useStyles = makeStyles((theme) => ({
    root1: {
        display: "flex",
        width: "40px",
        margin: "auto",
        flexWrap: "wrap",
        "& > *": {
            width: theme.spacing("auto"),
            height: theme.spacing("auto"),
        },
    },
    root2: {
        justifyContent: "center",
        display: "flex",
        flexWrap: "wrap",
        "& > *": {
            width: theme.spacing(120),
            height: theme.spacing("auto"),
        },
    },
    root3: {
        justifyContent: "center",
    },


    inner: {
        textAlign: "justify",
        justifyContent: "center",
        display: "flex",
        flexWrap: "wrap",
        width: "90%",
    },

    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

export default function InfoButtonScenarioDetails() {
    const [value, setValue] = useState();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen(!open);
    };

    return (
        <div>
            <IconButton
                className={classes.root1}
                variant="outlined"
                color="#ccc"
                onClick={handleToggle}
            >
                <HelpOutlineIcon fontSize="large" />
            </IconButton>
            <Backdrop
                className={classes.backdrop}
                open={open}
                onClick={handleClose}
                transitionDuration={425}
            >
                {/* <CircularProgress color="inherit" /> */}
                <div className={classes.root2}>
                    <Paper elevation={24} square={false}>
                        <hr />
                        <h3>  Scenario Details: </h3>
                        <hr />

                        <center>
                            <div className={classes.inner}>
                                As mentioned above, ECRA’s grinding expert team as
                                identified 5 different categories in order to create a
                                realistic evaluation procedure. The category ‘quality’
                                and ‘feed / flexibility’ as well as their derived
                                criteria are covering the most relevant product related
                                specifications. Others like ‘operation’, ‘maintenance’,
                                ‘efficiency’ as well as ‘environmental impact’ reflect
                                process related aspects. To receive more detailed
                                information on each criterion, please use the
                                description therein.
                                
                                </div>
                        </center>
                        <hr />
                    </Paper>
                </div>
            </Backdrop>
        </div>
    );
}
