import React from "react";

class Footer extends React.Component {
    render() {
        return (
            <footer class="page-footer">
                <div class="container">
                   
                    <p1>
                            <a href="https://ecra-online.org/data-privacy-statement/">
                                Data Privacy Statement
                            </a>
 <br/>
                            <a href="https://ecra-online.org/homesite/">
                                Go to ECRA Homepage
                            </a>
                        
                   
                    <ul>
                        <p>
                            
                            <br />
                            ECRA <br />
                            European Cement Research Academy GmbH
                            <br />
                            Toulouser Allee 71
                            <br />
                            40476 Duesseldorf
                            <br />
                            Germany
                            <br />
                            <br />
                            Contact:
                            <br />
                            Phone +49 (0) 211 23 98 38 0<br />
                            Fax +49 (0) 211 23 98 38 500
                            <br />
                            info@ecra-online.org
                            <br />
                                <br /> 
                                 
                        </p>
                        </ul>
                        </p1>
                </div>
            </footer>
        );
    }
}

export default Footer;
