import React, { Component, useState } from "react";
//import axios from "axios";
//import { BrowserRouter as Router, Route } from "react-router-dom";
import "./custom.css";
import "./SCSS/mainStyle.scss";
import Header from "./Pages/header";
import Nav from "./Pages/nav";
import Footer from "./Pages/footer";
//import AHPalpha from "./Pages/02_AHP/02_AHP_Beta/ahp_Alpha";

//import Mainmenu from "./Pages/01_MainMenu/mainMenu";
//import ManuelSearch from "./Pages/02_Research/manuelSearch"; 

export default class App extends Component {
    static displayName = App.name;

   render() {
        return (
            <div> 
                <Header />
                <Nav />
                <Footer />
            </div>
        );
    }     
}
