import React, { useState } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Paper, Tab, Tabs } from "@material-ui/core";
import InfoButtonApp from "./infoButton_App";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

const useStyles = makeStyles((theme) => ({
    root1: {
        display: "flex",
        width: "40px",
        margin: "auto",
        flexWrap: "wrap",
        "& > *": {
            width: theme.spacing("auto"),
            height: theme.spacing("auto"),
        },
    },
    root2: {
        justifyContent: "center",
        display: "flex",
        flexWrap: "wrap",
        padding: "5rem 4rem 4rem 4rem",
        "& > *": {
            width: theme.spacing(120),
            height: theme.spacing("auto"),
        },
    },
    root3: {
        justifyContent: "center",
    },
    root4Button: {
        fullWidth: "true",
        display: "flex",
        margin: "auto",
        flexWrap: "wrap",
    },

    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

export default function InfoButtonFilter() {
    const [value, setValue] = useState();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen(!open);
    };

    return (
        <div>
            <Button
                className={classes.root4Button}
                variant="text"
                //size="small"
                onClick={handleToggle}
            >
                Which Filters are applied?
            </Button>

            <Backdrop
                className={classes.backdrop}
                open={open}
                onClick={handleClose}
                transitionDuration={425}
            >
                {/* <CircularProgress color="inherit" /> */}
                <div className={classes.root2}>
                    <Paper elevation={24} square={false}>
                        <hr />
                        <h3>Which filters are applied? </h3>
                        <hr />
                        <p>
                            <strong>General Information: </strong>Filters are
                            applied to exclude technology-types from the
                            evaluation procedure, which to not fulfil the chosen
                            ‘type of application’. The filters vary with the
                            ‘type of application’ and are active, if ‘all
                            suitable technologies’ is selected. For ‘choose
                            technologies individually’, no filters are applied
                            for the evaluation. In this case, the evaluation
                            results need to be interpreted carefully.
                        </p>
                        <br />
                        <p>
                            <strong>Crushing: </strong>Applied filters: Not yet
                            implemented.
                        </p>
                        <br />
                        <p>
                            <strong>Coarse grinding: </strong>Applied filters:
                            If the ‘maximum feed size’ {"<="} 5 mm or the
                            ‘typical fineness’ {">="} 5 mm a technology is
                            excluded from evaluation.
                        </p>
                        <br />
                        <p>
                            <strong>Fine grinding: </strong>Applied filters: If
                            the ‘maximum feed size’ {"<="} 5 mm or the ‘typical
                            fineness’ {">="} 90 µm a technology is excluded from
                            evaluation.
                        </p>
                        <br />
                        <p>
                            <strong>Ultra-fine grinding: </strong>Applied
                            filters: If the ‘typical fineness’ {">="} 8 µm a
                            technology is excluded from evaluation.
                        </p>
                        <br />
                        <p>
                            <strong>Raw material grinding: </strong>Applied
                            filters: If the ‘maximum feed size’ {"<="} 5 mm or
                            the ‘typical fineness’ {">="} 90 µm a technology is
                            excluded from evaluation. The applied bonus for
                            ‘drying’ has been increased.
                        </p>
                        <br />
                        <em>
                            Press anywhere on this page to close this info
                            window.
                        </em>
                        <hr />
                    </Paper>

                    {/* <Paper>
                        <br />

                        <Tabs
                            value={value}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={handleChange}
                        >
                            <Tab label="Active" />
                            <Tab label="Disabled" />
                            <Tab label="Active" />
                        </Tabs>
                    </Paper> */}
                </div>
            </Backdrop>
        </div>
    );
}
