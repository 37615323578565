import React from "react";
import IconSearch from "../../Container/Icons/iconDatabase";
import IconStart from "../../Container/Icons/iconStart";
import IconFormular from "../../Container/Icons/iconFormular";

export default function Mainmenu() {
    return (
        <div>
            <br />
            <br />
            <br />
            <main id="styles">
                <div class="container">
                    <br />
                    <br />
                    <ul class="features">
                        <li>
                            <span>
                                <IconSearch />
                                <a href="/technologydatabase">
                                    Database<span aria-hidden="true"></span>
                                </a>
                                <span> </span>
                            </span>
                        </li>
                        <li>
                            <span>
                                <IconStart />
                                <a href="/evaluation">
                                    Analysis<span aria-hidden="true"></span>
                                </a>
                                <span> </span>
                            </span>
                        </li>
                        <li>
                            <span>
                                <IconFormular />
                                <a href="mailto:fgt-support@ecra-online.org?subject=Ecra-FGT">
                                    Contact ECRA team<span aria-hidden="true"></span>
                                </a>
                                <span> </span>
                            </span>
                        </li>
                    </ul>
                </div>
            </main>

            <br />
            <br />
            <br />
            <br />
        </div>
    );
}
