import React, { useState } from "react";
import ArticelMillSelect from "./Container/articel_MillSelect";
import ArticelGuideline from "./Container/articel_Guideline";
import Scenarios from "./Container/articel_Scenario";
//import ArticelScenarioDetails from "./Container/articel_ScenarioDetails";
import TypeOfApplication from "./Container/articel_TypeOfApp";
import DataSummaryFunction from "./Container/DataSummary/dataSummary";
import theDefaultContext from "./defaultContext";
//import Filter from "./Container/articel_Filter";

// Tutorial Provider : https://www.youtube.com/watch?v=eV97KXhmaLY

export default function AHPalpha() {
    const { Provider } = theDefaultContext;

    /////////////////////////////////////////////////////////////////[TYPE OF APPLICATION] Value of Radio-choice
    const [finalValueTypeOfApp, setFinalValueTypeOfApp] = useState("");

    /////////////////////////////////////////////////////////////////[SZENARIOS] Slider aller PresetKategorien
    const [categoryQuality, setCategoryQuality] = useState("Provider Default");
    const [categoryFeedFlex, setCategoryFeedFlex] =
        useState("Provider Default");
    const [categoryOperation, setCategoryOperation] =
        useState("Provider Default");
    const [categoryMaintenance, setCategoryMaintenance] =
        useState("Provider Default");
    const [categoryEfficiency, setCategoryEfficiency] =
        useState("Provider Default");
    const [categoryEnvironment, setCategoryEnvironment] =
        useState("Provider Default");

    //////////////////////////////////////////////////////////// [SZENARIOS] Slider aller Custom-Werte
    const [categoryQualityCustom, setCategoryQualityCustom] = useState(0.28);
    const [categoryFeedFlexCustom, setCategoryFeedFlexCustom] = useState(0.15);
    const [categoryOperationCustom, setCategoryOperationCustom] =
        useState(0.17);
    const [categoryMaintenanceCustom, setCategoryMaintenanceCustom] =
        useState(0.15);
    const [categoryEfficiencyCustom, setCategoryEfficiencyCustom] =
        useState(0.2);
    const [categoryEnvironmentCustom, setCategoryEnvironmentCustom] =
        useState(0.05);

    ////////////////////////////////////////////////////////////////////[SZENARIO DETAILS] Slider Alle Custom-Werte
    const [psd, setPsd] = useState(0.35);
    const [maxFineness, setMaxFineness] = useState(0.3);
    const [dehydration, setDehydration] = useState(0.2);
    const [drying, setDrying] = useState(0.15);
    const [maxFeedSize, setMaxFeedSize] = useState(0.6);
    const [productChangingTime, setProductChangingTime] = useState(0.4);
    const [understandingOfProcess, setUnderstandingOfProcess] = useState(0.05);
    const [sensitivityOfProcess, setSensitivityOfProcess] = useState(0.25);
    const [controlabilityPersonnel, setControlabilityPersonnel] = useState(0.2);
    const [availableExperience, setAvailableExperience] = useState(0.2);
    const [throughput, setThroughput] = useState(0.3);
    const [maintenanceTime, setMaintenanceTime] = useState(0.4);
    const [complexityOfMaintenance, setComplexityOfMaintenance] = useState(0.2);
    const [wearCosts, setWearCosts] = useState(0.4);
    const [energyDemand, setEnergyDemand] = useState(0.95);
    const [waterDemand, setWaterDemand] = useState(0.05);
    const [dustEmissions, setDustEmissions] = useState(0.3);
    const [noise, setNoise] = useState(0.5);
    const [effectOfVibration, setEffectOfVibration] = useState(0.2);

    ///////////////////////////////////////////////////////////////////////[SZENARIO-DETAILS] Slider alle PresetKategorien
    // aktuell nur ein Preset vorhanden: "European Standard"

    //////////////////////////////////////////////////////////// [MILL SELECTION] Provider-Value
    const [millSelectionUserChoice, setMillSelectionUserChoice] = useState([0]);
    const [allMillsWithoutFilters, setAllMillsWithoutFilters] =
        useState(Boolean);
    const [millSelectionCountMill, setMillSelectionCountMill] = useState("");
    const [millSelectRadioStatus, setMillSelectRadioStatus] = useState("all");
    const [selectMillReceivedValue, setSelectMillReceivedValue] =
        useState("all");

    //////////////////////////////////////////////////////////////////////////Alle SwitchValues

    const [categoryRadioScenario, setCategoryRadioScenario] = useState(
        "European standard setting"
    );
    const [criteriaRadioScenario, setCriteriaRadioScenario] = useState("");
    const [limitOfSliderCategorie, setLimitOfSliderCategorie] = useState("");
    const [limitOfSliderCriteria, setLimitOfSliderCriteria] = useState("");



    return (
        <div>
            <main id="main" tabIndex="1">
                <div className="AHPalhpa">
                    <Provider
                        value={{
                            // (Radio-Butto) Type of Application
                            finalValueTypeOfApp,
                            setFinalValueTypeOfApp,

                            // (SwitchValues) Status der aktiven Benutzer-Auswahl für alle Artikel
                            categoryRadioScenario,
                            setCategoryRadioScenario,
                            criteriaRadioScenario,
                            setCriteriaRadioScenario,
                            limitOfSliderCategorie,
                            setLimitOfSliderCategorie,
                            limitOfSliderCriteria,
                            setLimitOfSliderCriteria,

                            //Kategorien [CUSTOM]
                            categoryQualityCustom,
                            setCategoryQualityCustom,
                            categoryFeedFlexCustom,
                            setCategoryFeedFlexCustom,
                            categoryOperationCustom,
                            setCategoryOperationCustom,
                            categoryMaintenanceCustom,
                            setCategoryMaintenanceCustom,
                            categoryEfficiencyCustom,
                            setCategoryEfficiencyCustom,
                            categoryEnvironmentCustom,
                            setCategoryEnvironmentCustom,

                            //Kategorien [PRESET]
                            categoryQuality,
                            setCategoryQuality,
                            categoryFeedFlex,
                            setCategoryFeedFlex,
                            categoryOperation,
                            setCategoryOperation,
                            categoryMaintenance,
                            setCategoryMaintenance,
                            categoryEfficiency,
                            setCategoryEfficiency,
                            categoryEnvironment,
                            setCategoryEnvironment,

                            //Kriterien [CUSTOM]
                            psd,
                            setPsd,
                            maxFineness,
                            setMaxFineness,
                            dehydration,
                            setDehydration,
                            drying,
                            setDrying,
                            maxFeedSize,
                            setMaxFeedSize,
                            productChangingTime,
                            setProductChangingTime,
                            understandingOfProcess,
                            setUnderstandingOfProcess,
                            sensitivityOfProcess,
                            setSensitivityOfProcess,
                            controlabilityPersonnel,
                            setControlabilityPersonnel,
                            availableExperience,
                            setAvailableExperience,
                            throughput,
                            setThroughput,
                            maintenanceTime,
                            setMaintenanceTime,
                            complexityOfMaintenance,
                            setComplexityOfMaintenance,
                            wearCosts,
                            setWearCosts,
                            energyDemand,
                            setEnergyDemand,
                            waterDemand,
                            setWaterDemand,
                            dustEmissions,
                            setDustEmissions,
                            noise,
                            setNoise,
                            effectOfVibration,
                            setEffectOfVibration,

                            //Mill Selection
                            millSelectionUserChoice,
                            setMillSelectionUserChoice,
                            selectMillReceivedValue,
                            setSelectMillReceivedValue,
                            millSelectRadioStatus,
                            setMillSelectRadioStatus,
                            millSelectionCountMill,
                            setMillSelectionCountMill,
                            allMillsWithoutFilters,
                            setAllMillsWithoutFilters,
                        }}
                    >
                        {/* How to */}
                        <ArticelGuideline id="Guideline" />

                        {/*Type of Application*/}
                        <TypeOfApplication id="ApplicationType" />

                        {/*Scenarios*/}
                        <Scenarios id="Scenarios" />

                        {/*Scenario Details*/}
                        {/* <ArticelScenarioDetails id="ScenarioDetails" /> */}

                        {/*Mill selection*/}
                        <ArticelMillSelect id="MillSelection" />

                        {/* Mills filters  */}
                        {/* <Filter id="Filter" /> */}

                        {/*DataSummary and Next-Button */}
                        <DataSummaryFunction id="Result" />
                    </Provider>
                </div>
            </main>
        </div>
    );
}
